@value variables: "variables.css";
@value mobile-threshold-width from variables;

.headerLeft {
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-top: 5px;
}
.orderNumberContainer {
   display: flex;
   align-items: center;
   margin-bottom: 10px;
}
.banner {
   margin-bottom: 24px;
}
.tabsContainer {
   display: flex;
   z-index: 1;
   position: relative;
   margin-bottom: 24px;
}
.tabsContainer div {
   cursor: pointer;
}
.tabsSection {
   position: relative;
}
.tabsBorder {
   position: absolute;
   height: 2px;
   bottom: 1px;
}
.detailsHeader {
   display: flex;
   justify-content: space-between;
   margin: 62px 0 32px 0;
}
.detailsHeader p {
   margin: 0;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
}
.headerLeft h2 {
   color: var(--primary-text);
   font-size: 28px;
   line-height: 35px;
   margin: 0 12px 0 0;
}
.iconWrapper {
   background-color: #efeff1;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 92px;
   width: 92px;
   border-radius: 50%;
}
.loaderContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
}

@media (max-width: mobile-threshold-width) {
   .detailsHeader {
      flex-direction: column;
      padding-left: 0;
   }
   .detailsContainer {
      padding: 0 16px;
   }
   .reportLostDamagedPalletsButton {
      margin-top: 24px;
   }
   .loaderContainer {
      height: calc(100vh - 160px);
   }
}
