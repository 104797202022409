@value variables: "variables.css";
@value mobile-threshold-width from variables;

.outerContainer {
   position: relative;
}

.innerContainer {
   overflow: auto;
   max-height: 580px;
   background-color: white;
   border-radius: 8px;
   box-shadow: 0 2px 24px rgba(148, 148, 159, 0.12);
}

.innerLoader,
.outerLoader {
   width: 100%;
   display: flex;
   justify-content: center;
   padding: 10px 0;
   background-color: white;
}

.outerLoader {
   height: 580px;
   position: absolute;
   top: 0;
   align-items: center;
   background-color: rgba(255, 255, 255, 0.8);
   z-index: 1;
}

@media screen and (max-width: mobile-threshold-width) {
   .innerContainer {
      max-height: calc(100vh - 330px);
      min-height: calc(100vh - 730px);
      margin: 0 12px;
      box-shadow: none;
      background-color: inherit;
   }
}
