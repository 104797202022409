.freePalletsAmount {
   margin: 0;
   font-weight: 700;
   font-size: 14px;
   line-height: 24px;
   color: var(--primary-red);
}

.freePalletsAmount.green {
   color: var(--primary-green);
}
