.container {
   display: flex;
   flex-direction: column;
}

.container h4 {
   margin: 0 0 16px 0;
   font-size: 14px;
   line-height: 24px;
   color: var(--primary-text);
}

.summaryItem {
   padding: 24px;
   display: flex;
   flex-direction: column;
   border-radius: 8px;
   border: 1px solid var(--gray-border);
   margin-bottom: 16px;
}

.summaryItemTitle {
   margin: 0 0 12px 0;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
}

.summaryItemAmount {
   margin: 0;
   font-size: 22px;
   line-height: 28px;
   font-weight: 700;
   color: var(--primary-text);
}
