.textInput {
   width: 250px;
}

.textInput input {
   width: 100%;
   max-width: 100%;
   height: 48px;
   padding: 0 20px;
   box-sizing: border-box;
   border: 1px solid var(--gray-border);
}
.textInput input:hover {
   border: 2px solid var(--primary-green);
}
.textInput input:focus {
   box-shadow: 0px 0px 0px 2px rgba(148, 247, 163, 0.8);
   border: 2px solid var(--primary-green);
}
.textInput.error input {
   border: 2px solid var(--primary-red) !important;
   box-shadow: none;
}
.textInput label {
   margin-bottom: 4px;
   line-height: 24px;
   font-size: 14px;
   color: var(--secondary-text);
}
.textInput span {
   color: var(--primary-red);
}
