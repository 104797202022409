.loader {
   width: 100%;
   display: flex;
   justify-content: center;
   height: 580px;
   align-items: center;
}

.header {
   display: flex;
   margin-top: 56px;
   align-items: center;
   justify-content: space-between;
}

.headerLeft {
   display: flex;
   align-items: center;
   gap: 24px;
}

.header h1 {
   margin: 0;
   font-weight: 700;
   font-size: 28px;
   line-height: 35px;
}

.editBtn {
   position: absolute;
   right: 24px;
   top: 14px;
   padding: 10px 24px !important;
}

.editBtn > svg {
   width: 20px;
   height: 20px;
}

.distributionCenterContainer {
   margin-top: 32px;
}
