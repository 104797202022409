.additionalActionBtn {
   margin-bottom: 24px;
}

.tableWithChargesSummary tr:nth-last-child(2) td {
   padding-bottom: 24px;
}

.chargesTotalAmountRow td {
   padding-top: 16px;
}

.chargesTotalAmountCell {
   border-top: 1px solid var(--gray-border);
}

.chargesTotalAmountContainer {
   display: flex;
   align-items: center;
   justify-content: flex-end;
   font-weight: 700;
   font-size: 14px;
   line-height: 24px;
   color: var(--primary-text);
}

.chargesTotalAmountContainer span {
   margin-left: 28px;
}
