.container {
   display: flex;
   align-items: center;
   justify-content: flex-end;
   flex-wrap: wrap;
}

.container.leftSide {
   justify-content: flex-start;
}

.imagePreview {
   margin-left: 4px;
   margin-top: 4px;
}

.imagePreview.withDeleteButton {
   margin-left: 14px;
}
