.step {
  display: flex;
  align-items: center;
  color: #9ea3ad;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}
.counterContainer {
  color: white;
  background-color: #a6a6b2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-right: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  box-sizing: border-box;
}
.step.active {
  color: #121826;
}
.counterContainer.counterActive {
  background-color: #e4f1e6;
  color: #009653;
  border: none;
}
.counterContainer.counterCompleted {
  background-color: #009653;
}
