.inputContainer {
   width: 250px;
   display: flex;
   flex-direction: column;
   position: relative;
}
.inputContainer label {
   margin-bottom: 8px;
   font-size: 14px;
   line-height: 16px;
   color: var(--secondary-text);
}
.inputContainer.withLeftIcon svg {
   position: absolute;
   top: 13px;
   left: 23px;
}
.inputContainer.withRightIcon svg {
   position: absolute;
   top: 13px;
   right: 23px;
}
.inputContainer.withLabel svg {
   top: 40px;
}
.inputContainer.withLeftIcon input {
   padding-left: 52px;
}
.inputContainer.withRightIcon input {
   padding-right: 52px;
}
.inputContainer.fullWidth {
   width: 100%;
}
.inputContainer input {
   height: 48px;
   padding: 0 20px;
   border-radius: 4px;
   border: 1px solid #c7cad0;
   font-size: 14px;
   box-sizing: border-box;
}

.inputWithVisibleRightPart {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   direction: rtl;
   text-align: left;
}

.inputWithVisibleRightPart.focusedInput {
   direction: ltr;
}

.inputContainer input:focus,
.inputContainer input:focus-visible {
   outline: none;
   border: 1px solid var(--primary-green);
}
.inputContainer input::placeholder {
   color: #9ea3ad;
}
.inputContainer span {
   margin-top: 4px;
   font-size: 14px;
   color: #9ea3ad;
   line-height: 24px;
}
.inputContainer input:disabled {
   background-color: #f9f9fa;
   border: 1px solid #e0e0e7;
   cursor: not-allowed;
}
.error input,
.error input:hover,
.error input:focus {
   border: 2px solid #ff385f;
   box-shadow: none;
}

.error span {
   color: #ff385f;
}
.inputContainer .clearIcon {
   position: absolute;
   right: 16px;
   top: 15px;
   left: auto;
   cursor: pointer;
}
.inputContainer.withLabel .clearIcon {
   top: 43px;
}
.inputContainer .eyeIcon {
   position: absolute;
   right: 20px;
   cursor: pointer;
   left: auto;
}
.inputContainer .eyeIcon.eyeCrossedIcon {
   top: 13px;
}
.inputContainer.withLabel .eyeIcon.eyeCrossedIcon {
   top: 40px;
}
.inputContainer.passwordInput input {
   padding-right: 45px;
}
