.formContainer {
   height: calc(100% - 70px);
   display: flex;
   flex-direction: column;
}

.scrollableContainer {
   margin-bottom: 36px;
   max-height: 340px;
   min-height: 200px;
   overflow: auto;
}

.commercialNetworkNameCell {
   width: 320px;
}

.commercialNetworkNameSelect {
   width: 320px !important;
}

.commercialNetworkNameOptionsContainer {
   max-height: 300px;
}

.commercialNetworkAddressSelect {
   width: 100%;
}

.commercialNetworkAddressesMultiSelect > div:first-of-type {
   width: 100%;
}

.commercialNetworkAddressOptionsContainer {
   max-height: 300px;
   overflow: auto;
}

.removeCommercialNetworkItemBtnCell {
   width: 48px;
}

.removeCommercialNetworkItemBtnCell .removeCommercialNetworkItemBtn {
   width: 48px;
   padding: 0;
   color: #3e3e4b;
}

.bannersContainer {
   margin-top: 24px;
}

.errorBanner {
   margin-bottom: 12px;
}
