.palletsAmount {
   margin: 12px 0 0 0;
   font-size: 28px;
   line-height: 33px;
   font-weight: 700;
}

.palletsAmount span {
   font-size: 18px;
}
