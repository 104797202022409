.modalContent {
   margin-bottom: 72px;
}

.descriptionContainer {
   max-width: 428px;
   text-align: center;
}

.plainText {
   margin: 0;
   color: var(--primary-text);
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
}

.boldBlackText {
   margin: 0 0 20px 0;
   color: var(--primary-text);
   font-weight: 700;
   font-size: 14px;
   line-height: 24px;
}

.boldRedText {
   color: var(--primary-red);
   font-weight: 700;
   font-size: 14px;
   line-height: 24px;
}
