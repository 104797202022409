.container {
   display: flex;
   gap: 24px;
}

.column {
   display: flex;
   flex-direction: column;
   width: 350px;
   gap: 24px;
}

.multiRow {
   display: flex;
   gap: 24px;
}
.multiRow .sectionLabel {
   width: 113px;
}

.sectionLabel {
   color: var(--secondary-text);
}

.row p {
   margin: 4px 0;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
}
