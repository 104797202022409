.row {
   position: relative;
   border: 0;
}

.row .removeRowBtn {
   width: 22px;
   padding: 0;
   color: var(--gray-primary-text-color);
}

.cell {
   padding: 12px 24px 12px 0;
}

.cell:last-child {
   padding-right: 0;
}

.damageTypeCell {
   width: 317px;
}

.palletsAmountCell {
   width: 120px;
}

.palletsAmountCell .palletsAmountInput {
   width: 120px;
   height: 48px;
}

.uploadImagesCell {
   width: 150px;
}

.removeRowBtnCell {
   width: 22px;
}

.selectPalletType {
   min-width: 170px;
   max-width: 545px;
   width: 100%;
}

.selectPalletType > div {
   width: 100%;
}

.palletsAmountInput input {
   height: 48px;
}

.uploadImagesBtn {
   width: 150px;
   box-sizing: border-box;
}
