@value variables: "variables.css";
@value first-table-shrink-width from variables;

.innerContainer {
   margin-top: 48px;
   display: flex;
   justify-content: space-between;
}

.divider {
   height: 600px;
}

.damagedLostPalletsByClientSummary {
   width: 48%;
}

@media (max-width: first-table-shrink-width) {
   .innerContainer {
      margin-top: 24px;
      flex-direction: column;
   }

   .damagedLostPalletsByClientSummary {
      width: 100%;
   }

   .damagedLostPalletsByClientSummary:first-of-type {
      margin-bottom: 48px;
   }
}
