.paletteType {
   letter-spacing: 0.001em;
   margin: 0 0 3px;
}

.paletteDimensions {
   color: var(--secondary-text);
   font-size: 12px;
   line-height: 18px;
   margin: 0;
}

.inputContainer {
   display: flex;
   justify-content: flex-end;
}

.input {
   width: 100%;
}

.input > input {
   text-align: right;
}

.nameCell {
   width: 130px;
   text-align: left;
}
.nameCell:first-child {
   margin-right: 60px;
}

.cellWithoutArrow input::-webkit-outer-spin-button,
.cellWithoutArrow input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
.cellWithoutArrow input[type='number'] {
   -moz-appearance: textfield;
}

.cellGap {
   padding-right: 115px !important;
}

.tableRow {
   border-bottom: 1px solid #e6e8eb !important;
}
.tableRow td {
   padding-left: 0;
}

.editCellValue {
   padding: 0 20px;
}
