@value variables: "variables.css";
@value mobile-threshold-width from variables;

.datesContainer {
   display: flex;
}

.datePickerContainer p {
   margin: 4px 0 0 0;
   font-size: 14px;
   line-height: 24px;
   color: var(--primary-red);
}

.dateInput {
   height: 48px;
   font-size: 14px;
   line-height: 24px;
}

.dateInput:focus {
   box-shadow: 0px 0px 0px 2px rgba(148, 247, 163, 0.8);
   border: 1px solid var(--primary-green);
}

.dateInput.errorInput {
   border: 1px solid var(--primary-red);
}

.dateInput.errorInput:focus {
   border: 1px solid var(--primary-red);
   box-shadow: none;
}

.dateInputLabel {
   font-size: 14px;
   line-height: 24px;
   font-weight: 400;
   color: var(--gray);
}

.datePickerDay[data-selected] {
   background-color: var(--primary-green);
}

.dateInfo {
   margin-left: 46px;
}

.dateInfo p {
   font-size: 14px;
   line-height: 24px;
   color: var(--gray);
   margin: 0 0 16.5px 0;
}

.dateInfo svg {
   margin-right: 14px;
}

.dateInfo div {
   display: flex;
   align-items: center;
   font-size: 14px;
   line-height: 24px;
}

@media screen and (max-width: mobile-threshold-width) {
   .datesContainer {
      flex-direction: column;
   }

   .dateInfo {
      margin-left: 0;
      margin-top: 48px;
   }
}
