.modalContent {
   margin-bottom: 48px;
}

.modalContent p {
   font-size: 14px;
   line-height: 24px;
}

.modalContent p:first-of-type {
   margin: 0 0 20px 0;
   font-weight: 400;
   color: var(--primary-text);
}

.modalContent p:last-of-type {
   margin: 0;
   font-weight: 700;
   color: var(--black);
}
