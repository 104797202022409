.header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 24px;
}

.actions {
   display: flex;
   gap: 24px;
}

.headerTitle {
   font-weight: 700;
   font-size: 18px;
   line-height: 24px;
   margin: 0 0 8px 0;
}

.headerSubtitle {
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   margin: 0;
   color: var(--secondary-text);
}
