.mainContainer {
   width: 100%;
}

.select {
   width: 100%;
   height: 48px;
   border: 1px solid #c7cad0;
   border-radius: 4px;
   cursor: pointer;
   padding: 12px 20px;
   box-sizing: border-box;
   font-size: 14px;
   line-height: 24px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   color: var(--gray-primary-text-color);
   background-color: white;
   transition: ease-in-out 0.1s;
}

.select:hover {
   border: 2px solid var(--primary-green);
}

.select.error {
   border: 2px solid var(--primary-red);
   box-shadow: none;
}

.selectLeftContent {
   width: 90%;
}

.selectLeftContent span {
   display: block;
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
}

.disabled {
   background-color: #e6e8eb;
   color: #9ea3ad;
}

.disabled svg {
   fill: #9ea3ad;
}

.selectLabel {
   margin: 0 0 4px 0;
   font-size: 14px;
   line-height: 24px;
   color: #9ea3ad;
}

.selectOptionsContainer {
   box-sizing: border-box;
   height: 100%;
   width: 100%;
   padding: 25px 16px 0 16px;
   position: fixed;
   top: 0;
   left: 0;
   background-color: white;
   z-index: 100;
}

.closeIconContainer {
   display: flex;
   justify-content: flex-end;
   margin-bottom: 14px;
}

.closeIconContainer svg {
   height: 14px;
   width: 14px;
}

.selectOptionsContainerLabel {
   margin: 0 0 24px 0;
   font-size: 18px;
   line-height: 24px;
   color: var(--primary-text);
}

.option {
   height: auto;
   padding: 24px 0;
   border-bottom: 1px solid var(--gray-border);
}

.option:first-child {
   padding: 12px 0 24px 0;
}

.option:last-child {
   padding: 24px 0 12px 0;
   border-bottom: none;
}

.selectOptionsInnerContainer {
   overflow: auto;
   max-height: calc(100% - 130px);
}

.selectOptionsContainerBtn {
   position: fixed;
   bottom: 0;
   left: 0;
   border-radius: 0 !important;
}
