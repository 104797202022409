.header {
    margin: 48px auto 24px;
}

.leftPartHeader {
    display: flex;
    gap: 12px
}

.searchInput {
    width: 315px !important;
    color: var(--gray-primary-text-color);
}

.clearFiltersButton {
    color: #686870 !important;
}

.clearFiltersButton:hover {
    color: var(--primary-red) !important;
}