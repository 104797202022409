.contentContainer {
   display: flex;
   flex-direction: column;
   width: 100%;
   align-items: center;
   justify-content: center;
   height: 180px;
}
.contentContainer div {
   flex-direction: column;
   text-align: center;
}
.contentContainer div p {
   margin-bottom: 6px;
   margin-top: 0;
   font-size: 14px;
}
