@value variables: "variables.css";
@value mobile-threshold-width from variables;

.container {
   background-color: white;
   width: 248px;
   height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 24px 12px;
   box-sizing: border-box;
   border-right: 1px solid var(--secondary-gray);
}
.logo {
   height: 27px;
}
.navigation {
   width: 100%;
   margin-top: 24px;
}
.navigation a {
   text-decoration: none;
}
.navItem {
   margin-bottom: 8px;
   border-radius: 4px;
   cursor: pointer;
   transition: ease-in-out 0.2s;
}

.mobileOnlyBtn {
   display: none;
}

.disabledLink {
   pointer-events: none;
}

.disabledLink div {
   background-color: #ceced4;
}

@media screen and (max-width: mobile-threshold-width) {
   .container {
      position: fixed;
      height: 52px;
      bottom: 0;
      padding: 0 60px;
      width: 100vw;
      z-index: 99;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04))
         drop-shadow(0px -8px 16px rgba(0, 0, 0, 0.08));
   }
   .logo {
      display: none;
   }
   .addOrdedBtn {
      box-shadow: 0px 6px 10px rgba(0, 101, 76, 0.25);
      background-color: var(--primary-green);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 52px;
      border-radius: 50%;
      margin-top: -50px;
   }
   .navigation {
      padding: 0 60px;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      margin: 0;
   }
   .mobileOnlyBtn {
      display: block;
   }
   .navItem {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 11px !important;
      width: 80px;
      padding-left: 0 !important;
   }
   .navItem svg {
      margin: 0 !important;
   }
   .navItem.navItemActive {
      padding-left: 0 !important;
      background-color: white !important;
      border-left: none !important;
   }
   .hideMobile {
      display: none;
   }
}
