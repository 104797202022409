.cell {
   max-width: 190px;
   width: 100%;
   font-size: 14px;
   line-height: 24px;
}
.cell:first-of-type {
   max-width: 150px;
   width: 100%;
}
.cell:first-of-type h3 {
   margin: 0;
   font-size: 14px;
   font-weight: 400;
}
.cell:first-of-type p {
   margin: 0;
   font-size: 12px;
   color: var(--secondary-text);
}
