.card {
   background-color: white;
   border: 1px solid #e6e8eb;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   transition: ease-in-out 0.2s;
   position: relative;
   cursor: pointer;
}
.card:hover {
   background-color: #f9fbfb;
}
.title {
   font-weight: 700;
   font-size: 14px;
   line-height: 24px;
   margin-bottom: 4px;
}
.card.checked {
   background-color: #e3fde5;
   border: 1px solid #00af50;
}
.checkContainer {
   position: absolute;
   top: 30px;
   right: 30px;
}
.card.small {
   box-sizing: border-box;
   align-items: flex-start;
   justify-content: flex-start;
}
.card.small .title {
   font-size: 18px;
}
.card.disabled {
   background-color: #f9fbfb;
   cursor: pointer;
}
