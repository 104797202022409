@value variables: "variables.css";
@value mobile-threshold-width from variables;

.mainContainer {
   padding: 48px 0;
}

.headerContainer {
   display: flex;
   flex-direction: column;
}

.bannersContainer.withAlertBanners {
   margin-bottom: 48px;
}

.banner {
   margin-top: 12px;
}

.suspensionInfoBanner {
   align-items: flex-start;
}

.suspensionInfoBanner p {
   margin: 0;
}

.banner:first-child {
   margin-top: 0;
}

.header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 24px;
}

.header > div {
   display: flex;
}

.searchInput {
   color: var(--gray-primary-text-color);
}

.searchInput svg {
   top: 17px !important;
   width: 17px;
   height: 17px;
}

.searchInput input {
   height: 48px;
}

.searchBtn {
   margin-left: 24px;
   padding: 12px 32px;
   height: 48px !important;
}

.bannersContainer .mobileInfoBanner {
   display: none;
}

@media screen and (max-width: mobile-threshold-width) {
   .searchInput input {
      border-top: none !important;
      border-left: none !important;
      border-radius: 0 !important;
   }

   .searchBtn {
      display: none !important;
   }

   .mainContainer {
      min-height: calc(100vh - 170px);
      padding: 0;
      width: 100vw;
   }

   .headerContainer {
      flex-direction: column-reverse;
   }

   .header {
      align-items: flex-start;
   }

   .tableHideMobile {
      display: none;
   }

   .bannersContainer {
      margin-bottom: 24px;
      padding: 0 16px;
      box-sizing: border-box;
   }

   .bannersContainer.withAlertBanners {
      margin-bottom: 24px;
   }

   .bannersContainer .mobileInfoBanner {
      display: flex;
   }
}
