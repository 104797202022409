.cardsContainer {
   display: flex;
   gap: 16px;
}
.rentPeriodContainer p:first-of-type {
   color: var(--secondary-text);
   margin: 0 0 16px 0;
}
.rentPeriodContainer p:last-of-type {
   margin: 0 0 0 0;
}
.rentPeriodContainer {
   margin-bottom: 48px;
   font-size: 14px;
}
.rowHeader {
   font-size: 14px !important;
   color: var(--primary-text) !important;
}
.columnHeader h3,
.columnHeader p {
   font-size: 14px;
}
.columnHeader h3 {
   margin: 0;
}
.columnHeader p {
   margin: 4px 0 0 0;
   font-weight: bold;
   color: var(--gray-section-subtitle);
}
