@value variables: "variables.css";
@value first-table-shrink-width from variables;

.header {
   height: 72px;
   padding: 0 40px;
   background-color: white;
}

.header nav {
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: flex-start;
}

.logo {
   height: 48px;
}

@media (max-width: first-table-shrink-width) {
   .header {
      height: 52px;
   }

   .header nav {
      justify-content: center;
   }

   .logo {
      height: 26px;
   }
}
