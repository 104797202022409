.container h3 {
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
   margin-top: -40px;
   margin-bottom: 48px;
   max-width: 100%;
   text-align: left;
}

.modalContent {
   align-items: flex-start;
   margin: 0 auto 40px auto;
   position: relative;
}

.errorBanner {
   margin-top: 24px;
}
