.infoBanner {
   margin-bottom: 48px;
   align-items: flex-start;
}

.infoBanner p {
   margin: 0;
}

.palletCardsContainer {
   max-height: 460px;
   margin-bottom: 48px;
   padding-right: 5px;
   overflow: auto;
}

.palletCard {
   margin-bottom: 24px;
}
