.btn {
   height: 48px;
   padding: 12px 24px;
   border-radius: 4px;
   font-size: 14px;
   line-height: 24px;
   font-weight: bold;
   font-family: 'Source Sans Pro', sans-serif;
   border: none;
   box-sizing: border-box;
}

.btn.loading {
   cursor: default;
   opacity: 0.7;
   border: none;
}

.btn.disabled svg {
   pointer-events: none;
}

.btn.filledPrimary {
   background-color: var(--primary-green);
   color: white;
}

.btn.filledPrimary:hover {
   background-color: var(--dark-green);
}

.btn.outlinedPrimary {
   background-color: transparent;
   color: var(--primary-green);
   border: 1px solid var(--primary-green);
}

.btn.outlinedPrimary:hover {
   background-color: var(--primary-50);
   border-width: 2px;
}

.btn.outlinedPrimary.loading:hover {
   border-width: 1px;
}

.btn.outlinedPrimary.disabled {
   border: 1px solid #ceced4 !important;
   color: #ceced4 !important;
   background-color: transparent !important;
}

.btn.outlinedPrimary.disabled:hover {
   background-color: transparent;
}

.btn.filledDanger {
   background-color: var(--primary-red);
   color: white;
}

.btn.filledDanger:hover {
   background-color: #b71c58;
}

.btn.filledPrimary.disabled,
.btn.filledDanger.disabled {
   border: none;
   color: #a6a6b2;
   background-color: #dfdfe3;
}

.btn.outlinedDanger {
   background-color: transparent;
   color: var(--primary-red);
   border: 1px solid var(--primary-red);
}

.btn.outlinedDanger:hover {
   border-width: 2px;
   background-color: #ffefeb;
}

.btn.filledWarning {
   background-color: #fdd53f;
   color: var(--gray-primary-text-color);
}

.btn.filledWarning:hover {
   background-color: #d8a000;
}

.btn.outlinedWarning {
   background-color: transparent;
   color: var(--gray-primary-text-color);
   border: 1px solid #fdd53f;
}

.btn.outlinedWarning:hover {
   background-color: #d8a000;
}

.btn.text {
   background-color: inherit;
   color: var(--primary-green);
   padding: 12px 24px;
   border: none;
}

.btn.text.disabled,
.btn.text.disabled:hover {
   color: #ceced4;
   background-color: transparent !important;
}

.btn.text:hover {
   background-color: inherit;
}
.btn.outlined {
   background-color: inherit;
   color: var(--primary-green);
   border: 1px solid var(--primary-green);
}
.btn.outlined:hover {
   background-color: var(--primary-green);
   color: white;
}
