.modalContent {
    display: flex;
    align-items: flex-start;
    gap: 24px
}

.modalHeader {
    margin-bottom: 32px;
}

.modalLoader {
    height: 280px;
}

.actionBodyContainer {
    max-height: 500px;
    overflow-y: auto;
}