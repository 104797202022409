@value variables: "variables.css";
@value first-table-shrink-width from variables;

.container {
   position: relative;
   background-color: white;
   padding: 24px;
   margin-bottom: 24px;
   border-radius: 16px;
   box-shadow: 0px 2px 24px rgba(148, 148, 159, 0.12);
}
.container.innerContainer {
   box-shadow: none;
   border: 1px solid var(--gray-border);
   border-radius: 8px;
}
.header {
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
}
.containerTitle {
   margin: 0 0 24px 0;
   font-size: 18px;
   line-height: 24px;
   color: var(--primary-text);
}
.containerTitle.innerContainerTitle {
   font-size: 14px;
}
.containerTitle.withSubtitle {
   margin-bottom: 0;
}
.subtitle {
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
   margin: 4px 0 24px 0;
}
.saveCancelBtnsContainer {
   margin-bottom: 24px;
}
.saveCancelBtnsContainer.divider {
   border-bottom: 1px solid var(--gray-border);
   padding-bottom: 24px;
}
@media screen and (max-width: first-table-shrink-width) {
   .container {
      margin-bottom: 16px;
   }
}
