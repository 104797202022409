.contentContainer {
   margin-top: 48px;
}
.tabsContainer {
   display: flex;
   margin-bottom: 24px;
}
.tabsContainer > div {
   cursor: pointer;
}
.tableLoaderContainer {
   position: relative;
}
.tableContainer {
   max-height: 600px;
   position: relative;
   overflow: auto;
   box-shadow: 0px 2px 24px rgba(148, 148, 159, 0.12);
   background-color: white;
}
.clientsTable table tbody tr td:first-of-type {
   width: 14px;
   padding-left: 24px;
   padding-right: 0;
}
.clientsTable table tbody tr td:last-of-type {
   width: 140px;
}
.clientsTable table thead tr th:first-of-type {
   padding-left: 24px;
   padding-right: 0;
}
.clientsTable table thead tr th:last-of-type {
   padding-left: 40px;
}
.clientsTable table tbody tr {
   border-bottom: 1px solid var(--secondary-gray);
}
.searchContainer {
   display: flex;
   align-items: center;
   margin-bottom: 24px;
}
.searchContainer button {
   height: 48px;
   margin-left: 24px;
}
.actionsContainer {
   display: flex;
   align-items: center;
   justify-content: flex-end;
   float: right;
}
.detailsButton {
   height: 24px !important;
}
.dotsIcon {
   margin-left: 24px;
   cursor: pointer;
}
.loader {
   width: 100%;
   display: flex;
   justify-content: center;
   padding: 10px 0;
   background-color: white;
}
.loader.mainTableLoader {
   height: 600px;
   position: absolute;
   top: 0;
   align-items: center;
   background-color: rgba(255, 255, 255, 0.8);
   z-index: 1;
}
.deactivatedUser td {
   opacity: 0.5;
   padding: 12px 24px;
}
.deactivatedUser:hover td:nth-last-child(1) {
   opacity: 1;
}
.placeholder {
   width: 36px;
   height: 36px;
   margin-left: 34px;
}
.idColumnTooltipContent {
   top: calc(32px * -1) !important;
}
.idColumnContent {
   display: inline-block;
   max-width: 70px;
   overflow: hidden;
   text-overflow: ellipsis;
   user-select: none;
}
