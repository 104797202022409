.radioButton {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}

.radioButton.button {
  padding: 12px 16px;
  justify-content: center;
  border: 1px solid #c7cad0;
  border-radius: 2px;
  color: #121826;
  background-color: transparent;
  cursor: pointer;
}

.radioButton.button.selected {
  border-color: #00af50;
}

.radioButton.button.selected label {
  color: #00af50;
}

.radioButton.button.error {
  border-color: #ff385f;
}

.radioButton.button.error label {
  color: #121826;
}

.radioButton.button input[type='radio'] {
  display: none;
}

.radioButton input[type='radio'] {
  -webkit-appearance: none;
  margin: 0 12px 0 0;
  width: 20px;
  height: 20px;
  border: 1.5px solid #d2d2dc;
  border-radius: 50px;
  outline: none;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.radioButton.error input[type='radio'] {
  border: 1.5px solid red;
}

.radioButton input[type='radio']:hover {
  border: 2px solid #d2d2dc;
}

.radioButton input[type='radio']:before {
  content: '';
  width: 8px;
  height: 8px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  transform-origin: center;
  clip-path: circle(40% at 50% 50%);
}

.radioButton input[type='radio']:checked:before {
  background-color: white;
  transform: scale(1);
}

.radioButton input[type='radio']:checked {
  border: none;
  background-color: #3c43dc;
}

.radioButton label {
  color: #3e3e4b;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.radioButton.disabled input[type='radio'] {
  background-color: #f9f9fa;
}
.radioButton.disabled input[type='radio']:checked {
  background-color: #c2c3d0;
}
.radioButton.disabled label {
  color: #b1b2c2;
}
