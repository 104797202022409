tr.tableRow {
   border-bottom: 1px solid var(--secondary-gray);
}

.tableRow.blockedClientOrder {
   opacity: 0.4;
}

.tableRow.newOrder .tableCell {
   font-weight: 700;
}

.tableRow .tableCell:first-of-type {
   padding-left: 24px;
}

.severalTags {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
}

.severalTags div:first-of-type {
   margin-bottom: 4px;
}

.freePalletsAmount {
   padding-left: 5px;
   font-weight: 700;
   color: var(--primary-red);
}

.freePalletsAmount.green {
   color: var(--primary-green);
}

.tableRow .actionBtn {
   height: fit-content;
   padding: 0;
   font-weight: 700;
   line-height: 24px;
}
