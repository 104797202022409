.row {
    margin-bottom: 0;
    padding: 12px;
}

.row:last-child {
    margin-bottom: 12px;
}

.cell:first-child {
    max-width: 135px;
}


.changedPriceInput input {
    background-color: #FEF7CB;
}

.changedPriceInput svg {
    color:#FCC100;
}