.modalTitleSection {
   padding: 0 5px;
}

.modalTitle {
   margin: 0 0 8px 0;
   font-weight: 700;
   font-size: 28px;
}

.modalInfo {
   margin: 0;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
}

.modalContent {
   margin-bottom: 48px;
   padding: 5px;
   max-height: 550px;
   overflow: auto;
}

.formContentContainer {
   width: 100%;
}

.commercialNetworkNameContainer {
   margin-bottom: 36px;
}

.commercialNetworkNameContainer p {
   font-size: 14px;
   line-height: 24px;
}

.commercialNetworkNameContainer p:first-child {
   margin: 0 0 16px 0;
   color: var(--secondary-text);
}

.commercialNetworkNameContainer p:last-child {
   margin: 0;
   color: var(--primary-text);
}

.input {
   margin-bottom: 24px;
}

.inputsGroup {
   width: 100%;
   display: flex;
   justify-content: space-between;
   margin-bottom: 24px;
}

.inputsGroup:last-of-type,
.input:last-of-type {
   margin-bottom: 0;
}

.zipCodeInput {
   width: 150px;
   margin-right: 24px;
}

.cityInput {
   width: 360px;
}
