.negative {
   color: var(--primary-red);
   font-weight: bold;
}

.paletteType {
   letter-spacing: 0.001em;
   margin: 0 0 3px;
}

.paletteDimensions {
   color: var(--secondary-text);
   font-size: 12px;
   line-height: 18px;
   margin: 0;
}

.inputContainer {
   display: flex;
   justify-content: flex-end;
}

.input {
   width: 100%;
}

.input > input {
   text-align: right;
}

.nameCell {
   width: 150px;
   text-align: left;
}

.tableRow {
   border-bottom: 1px solid #e6e8eb !important;
}
