.tableContainer table {
   border-collapse: collapse;
   background-color: white;
   border-radius: 8px;
   width: 100%;
}
.tableHeader {
   padding: 10px 30px;
   font-weight: 600;
   font-size: 14px;
   line-height: 24px;
   text-align: left;
}
.tableHeaderInnerContainer {
   display: flex;
   flex-direction: column;
   justify-content: center;
}
.tableHeaderInnerContainer.right {
   align-items: flex-end;
}
.tableHeaderInnerContainer.left {
   align-items: flex-start;
}
.tableHeaderInnerContainer.center {
   align-items: center;
}
.tableHeaderText {
   display: flex;
   flex-direction: column;
   justify-content: center;
}
.tableHeaderText.noWrap {
   flex-direction: row;
   justify-content: flex-start;
   gap: 4px
}
.tableHeaderRow {
   border-bottom: 1px solid #e6e8eb;
}
.tableHeaderRow.fixed {
   position: sticky;
   top: 0;
   z-index: 1;
   background-color: white;
}
.tableContainer {
   border-radius: 8px;
   background-color: white;
   box-sizing: border-box;
   padding: 0 8px;
   border-collapse: collapse;
}
.sortIcon {
   margin-left: 10px;
   cursor: pointer;
}

.tableHeaderRow:last-child {
   text-align: right;
}
.tableHeaderAdditionalInfo {
   color: #a6a6b2;
}
