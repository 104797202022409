.tableRow {
   position: relative;
   border: 0;
}

.tableRow .removeTableRowBtn {
   width: 22px;
   padding: 0;
   color: var(--gray-primary-text-color);
}

.tableCell {
   padding: 12px 24px 12px 0;
}

.tableCell:last-child {
   padding-right: 0;
}

.tableCell .addPalletTypeBtn {
   padding: 0;
}

.sendDamagedPalletsAlertCell {
   width: 24px;
}

.sendDateCell {
   width: 140px;
}

.sendDatePicker input {
   width: 140px;
   padding: 12px 20px 12px 40px;
}

.externalCommercialNetworkNumberCell,
.externalCommercialNetworkNumberInput {
   width: 140px;
}

.externalCommercialNetworkNumberInput input {
   color: #39414f;
}

.commercialNetworkNameCell {
   width: 165px;
}

.selectCommercialNetworkNameContainer {
   width: 165px;
}

.selectCommercialNetworkNameContainer > div {
   width: 100%;
}

.selectCommercialNetworkAddressContainer {
   min-width: 260px;
   max-width: 390px;
   width: 100%;
}

.selectCommercialNetworkAddressContainer > div {
   width: 100%;
}

.dropdownBtn,
.dropdownBtn:disabled,
.dropdownBtn:hover,
.dropdownBtn:disabled:hover {
   background-color: inherit;
}

.dropdownBtn {
   padding: 0;
   justify-content: flex-start;
   border: none;
   font-weight: 400;
   color: var(--gray-primary-text-color);
}

.palletTypeCell {
   width: 270px;
}

.selectPalletType {
   width: 270px;
}

.selectPalletType > div {
   width: 100%;
}

.palletsAmountCell {
   width: 120px;
}

.palletsAmountInput {
   width: 120px !important;
}

.removeTableRowBtnCell {
   width: 22px;
}
