.textInput {
   height: 48px;
   box-sizing: border-box;
   padding: 0 20px;
   border: 1px solid var(--gray-border);
   font-size: 14px;
   border-radius: 4px;
}
.textInput:focus {
   box-shadow: 0px 0px 0px 2px rgba(148, 247, 163, 0.8);
   border: 2px solid var(--primary-green);
   outline: none;
}
.inputContainer {
   display: flex;
   position: relative;
   flex-direction: column;
}
.inputContainer label {
   margin-bottom: 4px;
   line-height: 24px;
   font-size: 14px;
   color: var(--secondary-text);
}
.errorMessage {
   color: var(--primary-red);
   font-size: 14px;
   margin-top: 4px;
}
.error,
.error:focus {
   border: 2px solid var(--primary-red);
}
.error:focus {
   box-shadow: none;
}
