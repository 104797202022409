.modalContent {
   align-items: flex-start;
   margin: 0 auto 40px auto;
   text-align: center;
   position: relative;
}

.summaryFirstCell {
   text-align: left;
   font-weight: bold;
   padding-top: 25px !important;
}
.summaryCell {
   text-align: right;
   padding-top: 25px !important;
}

.table th:nth-child(2) span,
.table th:nth-child(3) span,
.table th:nth-child(4) span {
   width: 75px;
}
