.footerBtnsContainer {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: flex-end;
   gap: 12px;
   margin-top: 36px;
}
.footerBtnsContainer button {
   width: 120px;
}
