.btn {
   height: 44px;
   padding: 12px 24px;
   cursor: pointer;
   transition: ease-in-out 0.2s;
   display: flex;
   align-items: center;
   border-radius: 4px;
   justify-content: center;
   font-size: 14px;
   border: none;
   background-color: var(--primary-green);
   color: white;
}
.btn:hover {
   background-color: var(--dark-green);
}
.btn.disabled,
.btn.disabled:hover {
   cursor: not-allowed;
   background-color: #c2c3d0;
}
.small {
   padding: 4px 12px;
   height: 32px;
}
.fullWidth {
   width: 100%;
}
.iconLeft svg,
.loading svg {
   margin-right: 13px;
}
.iconRight svg {
   margin-left: 13px;
}
.btn.iconOnly svg {
   margin: 0;
}
