.btn {
   height: 48px;
   padding: 12px 32px;
   display: flex;
   align-items: center;
   cursor: pointer;
   font-weight: 700;
   font-size: 14px;
   line-height: 24px;
   border-radius: 4px;
   box-sizing: border-box;
}

.btn.filledPrimary {
   background-color: var(--primary-green);
   color: white;
}

.btn.filledPrimary:hover {
   background-color: var(--dark-green);
}

.btn.outlinedPrimary {
   background-color: transparent;
   color: var(--primary-green);
   outline: 1px solid var(--primary-green);
}

.btn.outlinedPrimary:hover {
   background-color: var(--primary-50);
   outline-width: 2px;
}

.btn.loading {
   cursor: default;
   opacity: 0.7;
   border: none;
}

.btn.loading svg {
   margin-right: 13px;
}

.input {
   opacity: 0;
   position: absolute;
   z-index: -1;
}
