@value variables: "variables.css";
@value first-table-shrink-width from variables;

.mainContainer {
   min-height: calc(100vh - 210px);
   padding-top: 48px;
}

.banner {
   margin-bottom: 24px;
}

@media (max-width: first-table-shrink-width) {
   .mainContainer {
      height: auto;
      padding: 24px 16px 0 16px;
   }
}
