@value variables: "variables.css";
@value mobile-threshold-width from variables;

.card {
   width: 260px;
   padding: 42px 24px 24px 24px;
   border-radius: 8px;
}

.cardContent {
   width: 100%;
}

@media screen and (max-width: mobile-threshold-width) {
   .card {
      margin-bottom: 16px;
      border-radius: 8px;
   }
}
