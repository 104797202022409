.container {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.container h2 {
   margin: 0 0 24px 0;
   font-size: 28px;
   line-height: 35px;
   color: var(--primary-text);
}

.container p {
   margin: 0 0 24px 0;
   text-align: center;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
}
