.container input:hover {
   border: 2px solid #00af50;
}

.container input:focus {
   border: 2px solid var(--primary-green);
}

.container.error input {
   border: 2px solid var(--primary-red);
   box-shadow: none;
}

.container.disabled input {
   border: 1px solid #e0e0e7;
}
