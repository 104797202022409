.container h3 {
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
   margin-top: -30px;
   margin-bottom: 48px;
   max-width: 100%;
   text-align: left;
}

.modalContent {
   align-items: flex-start;
   margin: 0 auto 40px auto;
   position: relative;
}
.modalContent table {
   margin-top: 24px;
}
.modalContent th:nth-child(2),
.modalContent th:nth-child(3),
.modalContent th:nth-child(4) {
   text-align: right;
   width: 120px;
}

.commercialNetworkNameContainer {
   margin-bottom: 36px;
}

.commercialNetworkNameContainer p {
   font-size: 14px;
   line-height: 24px;
}

.commercialNetworkNameContainer p:first-child {
   margin: 0 0 16px 0;
   color: var(--secondary-text);
}

.commercialNetworkNameContainer p:last-child {
   margin: 0;
   color: var(--primary-text);
}
.inputRow {
   display: flex;
   gap: 24px;
}
