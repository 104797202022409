@value variables: "variables.css";
@value mobile-threshold-width from variables;

.summaryCardsContainer {
   display: flex;
   margin: 24px 0 24px 0;
   gap: 16px;
}

.summaryContainer {
   margin: 48px 0;
}

@media screen and (max-width: mobile-threshold-width) {
   .summaryCardsContainer {
      flex-direction: column;
   }

   .summaryCardsContainer > div {
      width: 100%;
   }
}
