.nameCell {
   display: flex;
   align-items: center;
   gap: 13px;
}

.nameCell span {
   flex-basis: 20px;
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.networkTableRow {
   background-color: var(--gray-table-extendable-row);
}

.networkTableRow:hover {
   background-color: #f7f7f8;
   cursor: pointer;
}

.networkTableRow > td:first-of-type {
   padding-left: 24px;
}

.bold {
   font-weight: 700;
}

.addressLengthCell {
   font-style: italic;
}

.actionContainer {
   width: 145px;
   display: flex;
   justify-content: flex-end;
}
