.modalContent {
    margin-bottom: 48px;
}

.modalContent h3 {
    max-width: 350px;
}

.modalContent p {
    margin-block: 0;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    color: var(--primary-text);
}