.outerTabsContainer {
   position: relative;
}

.innerTabsContainer {
   display: flex;
   z-index: 1;
}

.outerTabsContainer .tabsBottomBorder {
   position: absolute;
   height: 2px;
   bottom: 0;
}
