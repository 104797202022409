.container {
   display: flex;
   align-items: center;
}

.container.warning {
   color: var(--primary-red);
}

.tooltip {
   display: flex;
   align-items: center;
   margin-left: 10px;
}

.tooltip:last-child {
   margin-left: 8px;
}

.tooltip svg {
   width: 22px;
}
