.modalContent {
   color: var(--primary-text);
   margin-bottom: 48px;
}

.modalContent p {
   font-size: 14px;
   line-height: 24px;
}

.remainingPalletsContainer p:first-of-type {
   margin: 24px 0;
}

.remainingPalletsContainer ul li {
   margin: 14px 0;
}

.remainingPalletsText {
   margin-top: 24px;
}

.remainingPalletsContainer {
   font-size: 14px;
}
