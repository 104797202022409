.tabsSection {
   margin-bottom: 24px;
   position: relative;
}

.tabsContainer {
   display: flex;
   z-index: 1;
   position: relative;
}

.tabsContainer div {
   cursor: pointer;
}

.tabsBorder {
   position: absolute;
   height: 2px !important;
   bottom: 0;
}

.headerContainer {
   gap: 24px;
   margin: 48px auto 24px;
   display: flex;
   flex-direction: column;
}

.header {
   display: flex;
   gap: 24px;
}

.banner {
   width: 100%;
}

.line {
   border-left: 1px solid #dfdfe3;
   height: 48px;
}
