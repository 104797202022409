.btnGroupLabel {
   margin: 0 0 4px 0;
   font-size: 14px;
   line-height: 24px;
   color: #9ea3ad;
}

.btnGroup {
   display: flex;
}

.btnGroup button:first-child,
.btnGroup div:first-child {
   border-top-right-radius: 0px;
   border-bottom-right-radius: 0px;
}

.btnGroup button:last-child,
.btnGroup div:last-child {
   border-top-left-radius: 0px;
   border-bottom-left-radius: 0px;
}

.btnGroup button:not(:first-child, :last-child) {
   border-radius: 0px;
}
