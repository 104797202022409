.tableHeader {
   width: 318px !important;
}

.tableRow {
   align-items: flex-start;
   border-bottom: 1px solid var(--gray-border);
   margin-bottom: 12px;
   font-size: 14px;
}

.tableRow:last-child {
   border-bottom: 0;
}

.tableCellName,
.tableCellInformation {
   width: 318px !important;
   padding-bottom: 24px;
}

.tableCellInformation:last-child {
   padding-bottom: 12px;
}

.tableInnerContainer {
   margin-bottom: 12px;
   padding-bottom: 12px;
   border-bottom: 1px solid var(--gray-border);
   display: flex;
   align-items: center;
   gap: 16px;
}
