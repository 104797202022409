.table th:nth-child(2),
.table th:nth-child(3),
.table th:nth-child(4),
.table th:nth-child(5),
.table th:nth-child(6) {
   text-align: right;
   width: 150px;
}

.table td {
   padding: 0 24px;
}

.table table {
   margin-top: 24px;
}
