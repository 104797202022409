.container {
   display: flex;
}

.btn {
   width: 120px;
}

.btn:first-of-type {
   margin-right: 12px;
}
