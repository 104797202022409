.container {
   margin-bottom: 24px;
}

.banner {
   margin-bottom: 12px;
}

.banner:last-child {
   margin-bottom: 0px;
}
