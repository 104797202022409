.container {
   display: flex;
   flex-direction: column;
}

.label {
   font-weight: 400;
   line-height: 24px;
   color: var(--gray);
}

.input {
   height: 48px;
   display: flex;
   align-items: center;
   color: var(--gray-primary-text-color);
}

.label,
.input {
   font-family: 'Source Sans Pro', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.input:hover {
   border: 2px solid var(--primary-green);
}

.input:focus,
.input:focus-within {
   box-shadow: 0 0 0 2px rgba(148, 247, 163, 0.8);
   border: 2px solid var(--primary-green);
}

.input.error,
.input.error:focus,
.input.error:focus-within {
   border: 2px solid var(--primary-red);
   box-shadow: none;
}

.helperText {
   margin-top: 4px;
   font-size: 14px;
   line-height: 24px;
   color: #9ea3ad;
}

.helperText.error {
   color: #ff385f;
}
