.modalContent {
   align-items: flex-start;
   margin: 0 auto 40px auto;
   position: relative;
}

.modalContent table {
   margin-top: 24px;
}

.modalContent th:nth-child(2),
.modalContent th:nth-child(3),
.modalContent th:nth-child(4) {
   text-align: right;
   width: 70px;
}

.inputRow {
   display: flex;
   gap: 24px;
}

.datePicker {
   width: 200px;
}

.timePicker {
   width: 150px;
}

.banner {
   margin-bottom: 48px;
}
