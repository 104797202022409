.cardContainer {
   width: 90%;
   padding: 24px 16px;
   border-radius: 8px;
   border: 1px solid var(--gray-border);
}

.removeBtnContainer {
   margin-bottom: 24px;
   display: flex;
   justify-content: flex-end;
}

.chargeTypeRadioButtonsGroup {
   margin-bottom: 24px;
}

.chargeTypeRadioButtonsGroup:only-child {
   margin-bottom: 0;
}

.chargeTypeRadioButtonsGroup p {
   margin: 0 0 14px 0;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
}

.chargeTypeRadioButtonsGroup .chargeTypeRadioBtn {
   height: auto;
   margin-bottom: 16px;
}

.palletsAmountInput {
   width: 200px;
}

.formField {
   margin-bottom: 24px;
}

.amountInput {
   width: 200px;
}
