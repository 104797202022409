.headerContainer {
   gap: 24px;
   margin: 48px auto 24px;
   display: flex;
   flex-direction: column;
}

.banner {
   margin-top: 24px;
}
