@value variables: "variables.css";
@value first-table-shrink-width from variables;
@value mobile-threshold-width from variables;

.mainContainer {
   margin-top: 172px;
   width: 415px;
}

.mainContainer h1 {
   margin: 0 0 24px 0;
   font-weight: 700;
   font-size: 34px;
   line-height: 40px;
   color: var(--gray-primary-text-color);
}

.banner {
   margin-bottom: 24px;
}

.input {
   width: 100% !important;
}

.input:first-of-type {
   margin-bottom: 24px;
}

.input:last-of-type {
   margin-bottom: 48px;
}

@media (max-width: first-table-shrink-width) {
   .button {
      width: 100%;
   }
}

@media (max-width: mobile-threshold-width) {
   .mainContainer {
      margin-top: 68px;
      max-width: 415px;
      width: 100%;
   }

   .mainContainer h1 {
      font-size: 22px;
      line-height: 26px;
   }
}
