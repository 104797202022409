@value variables: "variables.css";
@value mobile-threshold-width from variables;
.mainContainer {
   position: relative;
   z-index: 2;
}

.statusLegendBanner {
   align-items: center;
   cursor: pointer;
   background-color: inherit !important;
   color: #a6a6b2 !important;
}
.statusLegendBanner span svg path {
   stroke: #a6a6b2;
}
.statusLegendBanner span svg circle {
   stroke: #a6a6b2;
}
.statusLegendContainer {
   position: absolute;
   right: 0;
   width: 610px;
   padding: 24px;
   border-radius: 8px;
   background-color: white;
   box-shadow: 0px 0px 0px 0px #1111111a, 0px 3px 6px 0px #1111111a, 0px 11px 11px 0px #11111117,
      0px 24px 14px 0px #1111110d, 0px 43px 17px 0px #11111103, 0px 67px 19px 0px #11111100;
}

.statusLegendContainer h4 {
   margin: 0 0 24px 0;
   font-weight: 700;
   font-size: 18px;
   line-height: 24px;
   color: var(--gray-primary-text-color);
}

.statusLegendContainer ul {
   margin: 0;
   padding: 0;
   list-style: none;
}
.bannerTextMobileOnly,
.mobileOnlyCloseBtn {
   display: none;
}
@media screen and (max-width: mobile-threshold-width) {
   .statusLegendContainer {
      width: 100vw;
      height: 100vh;
      top: 0;
      box-sizing: border-box;
      z-index: 99;
      padding: 52px 16px;
   }
   .mainContainer {
      position: static;
   }
   .bannerTextDesktopOnly {
      display: none;
   }
   .bannerTextMobileOnly {
      display: block;
   }
   .mobileOnlyCloseBtn {
      display: block;
      position: absolute;
      right: 16px;
      top: 16px;
   }
   .statusLegendBanner {
      background-color: white !important;
      width: calc(100vw - 250px) !important;
      border-bottom: 1px solid #c7cad0 !important;
      border-radius: 0 !important;
      height: 48px;
   }
}
