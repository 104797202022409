tr.tableRow {
   border-bottom: 1px solid var(--secondary-gray);
}

.tableRow .tableCell:first-of-type {
   padding-left: 24px;
}

.tableRow .tableCell:last-of-type {
   width: 140px;
}

.actionsContainer {
   display: flex;
   align-items: center;
   justify-content: flex-end;
   float: right;
}

.actionsDropdown {
   margin-left: 0;
}

.addressFirstLine {
   margin: 0;
   font-size: 14px;
   line-height: 24px;
}

.addressSecondLine {
   margin: 0;
   font-size: 12px;
   line-height: 18px;
   color: #a6a6b2;
}
