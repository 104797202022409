@value variables: "variables.css";
@value mobile-threshold-width from variables;

.orderForm {
   padding-bottom: 80px;
}

@media screen and (max-width: mobile-threshold-width) {
   .submitButton {
      border-radius: 0;
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: var(--primary-green) !important;
      color: white !important;
      z-index: 99;
      width: 100%;
   }
}
