.dropdown.withPortal {
   position: fixed;
   top: 0;
}
.dropdownActive {
   position: absolute;
   right: 0;
   z-index: 99;
   top: 38px;
   display: flex;
   box-shadow: 0 21px 9px rgba(17, 17, 17, 0.01), 0 12px 7px rgba(17, 17, 17, 0.05),
      0 5px 5px rgba(17, 17, 17, 0.09), 0 1px 3px rgba(17, 17, 17, 0.1), 0 0 0 rgba(17, 17, 17, 0.1);
   background-color: white;
   flex-direction: column;
   width: max-content;
   padding: 8px;
   border-radius: 8px;
   cursor: pointer;
}
.dropdownActive.dropdownLarge {
   top: 52px;
   padding: 10px;
}
.dotsContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 6px;
   border-radius: 8px;
   box-sizing: border-box;
   transition: ease-in-out 0.2s;
}
.dotsContainerLarge {
   width: 48px;
   height: 48px;
   border-radius: 4px;
}
.dotsContainerActive {
   background-color: #c5e2cb;
}
.dropdownContainer {
   position: relative;
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-left: 34px;
}
.option {
   padding: 8px 20px;
   display: flex;
   align-items: center;
   font-size: 14px;
   line-height: 24px;
   color: var(--gray-primary-text-color);
}
.option.dangerOption {
   color: var(--primary-red);
}
.option:hover {
   background-color: var(--light-gray);
}
.option svg {
   width: 20px;
   margin-right: 14px;
}
.divider {
   width: 100%;
   height: 1px;
   background-color: var(--secondary-gray);
   margin: 8px 0;
}
.lastOption {
   color: var(--primary-red);
}
.lastOption:hover {
   background-color: var(--light-red);
}
