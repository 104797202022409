@value variables: "variables.css";
@value first-table-shrink-width from variables;

.stepsContainer {
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;
   margin-bottom: 54px;
}

.stepsContainer .step {
   color: var(--secondary-text);
}

.stepsContainer .step.active {
   color: var(--gray-primary-text-color);
}

.chevron {
   margin: 0 25px;
}

.bannersContainer {
   margin-top: 72px;
}

.banner {
   margin-bottom: 12px;
}

.additionalFeeWarningErrorBanner {
   margin-top: 72px;
}

.additionalFeeWarningContainer {
   display: flex;
   flex-direction: column;
   margin-bottom: 72px;
}

.additionalFeeWarningContainerError {
   margin-bottom: 0;
}

.errorStepFooter {
   margin-top: 48px;
}

.loader {
   width: 100%;
   height: 344px;
   display: flex;
   justify-content: center;
   align-items: center;
}

@media (max-width: first-table-shrink-width) {
   .stepsContainer {
      margin-bottom: 40px;
   }

   .stepsContainer .step:not(.active) span {
      display: none;
   }

   .detailsContainer {
      height: calc(100vh - 200px);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
   }

   .bannersContainer {
      margin: 10px 0 24px 0;
   }

   .additionalFeeWarningContainer {
      height: calc(100vh - 290px);
      margin: 90px 0 0 0;
      justify-content: space-between;
   }
}
