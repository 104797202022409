.banner {
   width: fit-content;
   border-radius: 8px;
   padding: 12px 14px;
   box-sizing: border-box;
   display: flex;
   align-items: center;
   position: relative;
   text-align: left;
}
.banner.fullWidth {
   width: 100%;
}
.banner.withCloseIcon .textContainer {
   margin-right: 26px;
}
.icon {
   display: inline-flex;
}
.banner h2 {
   margin: 0;
   font-weight: 700;
   font-size: 18px;
   line-height: 24px;
}
.banner span {
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
}
.textContainer {
   margin-left: 14px;
}
.info {
   background-color: #cfeefe;
   color: #0c60d6;
}
.infoGray {
   background-color: #efeff1;
   color: #4a4a4f;
}
.warning {
   background-color: #fef7cb;
   color: #b58200;
}
.error {
   background-color: #ffefeb;
   color: #db285c;
}
.success {
   background-color: #e4f1e6;
   color: #009653;
}
.textContainer {
   display: flex;
   flex-direction: column;
}
.banner.withTitle {
   align-items: flex-start;
}
.closeIcon {
   cursor: pointer;
   margin-left: auto;
   flex: 0 0 auto;
}
.withTitle .closeIcon {
   margin-top: 6px;
}
.withTitle svg {
   margin-top: 2px;
}
