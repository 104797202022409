.headerRow,
.row {
   display: flex;
   align-items: center;
   gap: 16px;
}
.row {
   margin-bottom: 24px;
}
.row:last-of-type {
   margin-bottom: 12px;
}
