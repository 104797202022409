.container {
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   margin-bottom: 24px;
   padding: 24px 16px;
   border: 1px solid #dfdfe3;
   border-radius: 8px;
}

.container .removeBtn {
   width: 22px;
   padding: 0;
   color: #3e3e4b;
   margin-bottom: 20px;
}

.selectPalletType {
   margin-bottom: 24px;
}

.damageTypeBtnGroupContainer {
   width: 100%;
   margin-bottom: 24px;
}
