.banner {
   margin-bottom: 24px;
}

.card {
   width: 500px;
   padding: 42px 24px 24px 24px;
   border-radius: 8px;
   box-sizing: border-box;
}

.cardContent {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.cardDisabled {
   opacity: 0.6;
   pointer-events: none;
   z-index: 99;
   background-color: #efeff1;
}

.cardText {
   margin: 4px 0 24px 0;
   text-align: center;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-dark-gray);
   width: 390px;
}

.addressSelect {
   width: 450px;
}

.addressSelect > div:last-child {
   height: 400px;
   top: -405px;
   overflow: auto;
}
