.btnsContainer {
   display: flex;
   justify-content: flex-end;
   gap: 30px;
}

.deleteBtn {
   color: var(--text-primary) !important;
}
.commentsTable .btnsContainer button {
   padding: 0;
}
.btnsContainer button svg {
   margin-right: 11px;
}
.commentsTable table th:first-of-type,
.commentsTable table td:first-of-type {
   padding-left: 32px;
}
.noResultsContainer {
   padding: 100px 0;
   color: var(--gray-section-subtitle);
}
.noResultsContainer p {
   font-weight: bold;
   font-size: 14px;
   margin: 18px 0 32px 0;
}
.tableContainer {
   max-height: 500px;
   overflow-y: auto;
}
