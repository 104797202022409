.table {
   padding: 0;
   color: var(--primary-text);
}

.table .tableHeader {
   box-shadow: 0 1px 0 0 var(--secondary-gray);
}

.tableHeader th {
   padding: 12px 24px;
   font-weight: 700;
}

.tableHeader th:first-of-type {
   padding-left: 5px;
}

.table tr {
   padding: 0;
   border-bottom: none;
}

.table td {
   padding: 12px 24px;
   vertical-align: middle;
}

.table td:first-of-type {
   padding-left: 5px;
}
