@value variables: "variables.css";
@value mobile-threshold-width from variables;

.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   box-sizing: border-box;
   height: 100%;
   color: var(--gray-secondary-text-color);
}
.container h2 {
   width: 225px;
   font-size: 18px;
   line-height: 24px;
}
.textSection {
   display: flex;
   flex-direction: column;
   font-size: 14px;
   line-height: 24px;
   text-align: left;
   width: 450px;
}
.textBadgeRow {
   display: flex;
   align-items: center;
}
.innerContainer {
   width: 315px;
}
.textSection {
   width: 400px;
}
@media screen and (max-width: mobile-threshold-width) {
   .innerContainer {
      width: 85%;
   }
   .container {
      padding-top: 30px;
   }
   .textSection {
      width: 100%;
   }
}
