.headerContainer {
   gap: 24px;
   margin: 48px auto 24px;
   display: flex;
   flex-direction: column;
}

.banner {
   margin-bottom: 24px;
}

.tableLoaderContainer {
   position: relative;
}

.tableContainer {
   overflow: auto;
   max-height: 580px;
   background-color: white;
   border-radius: 8px;
   box-shadow: 0 2px 24px rgba(148, 148, 159, 0.12);
}

.tableHeader {
   position: sticky;
   top: 0;
   box-shadow: 0 1px 0 0 var(--secondary-gray);
   z-index: 1;
   background-color: white;
}

.tableContainer thead > tr {
   border-bottom: none;
}

.palletsTable .tableHeader th:first-of-type {
   padding-left: 24px;
}

.palletsTable .tableRow {
   border-bottom: 1px solid var(--secondary-gray);
}

.searchInput {
   width: 350px;
}

.loader {
   width: 100%;
   display: flex;
   justify-content: center;
   padding: 10px 0;
   background-color: white;
}

.loader.mainTableLoader {
   height: 580px;
   position: absolute;
   top: 0;
   align-items: center;
   background-color: rgba(255, 255, 255, 0.8);
   z-index: 1;
}
