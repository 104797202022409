.mainContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.leftPartContainer {
    display: flex;
    align-items: center;
    gap: 16px;
}

.actionTypeContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.actionTypeContainer span {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.001em;
    color: var(--secondary-text);
}

.additionalInfoContainer {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.001em;
    color: #686870;
}

.dateTimeContainer {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.001em;
    color: var(--secondary-text);
}