.modal {
   width: 600px;
}

.modalContent {
   margin: 0 auto 40px auto;
   text-align: center;
   position: relative;
}

.modalContent > svg {
   margin-bottom: 20px;
}

.modalContent h3 {
   margin: 0 0 12px 0;
   color: var(--primary-text);
   font-weight: 700;
   font-size: 22px;
   line-height: 27px;
}

.descriptionContainer {
   max-width: 430px;
   margin: 0 auto 72px auto;
}

.plainText {
   margin: 0;
   color: var(--primary-text);
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
}

.boldBlackText {
   margin: 0 0 20px 0;
   color: var(--primary-text);
   font-weight: 700;
   font-size: 14px;
   line-height: 24px;
}

.btnsContainer {
   display: flex;
   justify-content: flex-end;
}

.btnsContainer button:first-of-type {
   margin-right: 12px;
}
