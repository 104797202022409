.container {
   margin-bottom: 14px;
}

.container .removeBtnContainer button {
   width: 22px;
   padding: 0;
   color: #3e3e4b;
}

.removeBtnContainer {
   display: flex;
   justify-content: flex-end;
}

.inputsContainer {
   display: flex;
   justify-content: space-between;
}

.selectPalletTypeContainer {
   width: 65%;
}

.selectPalletType {
   width: 95%;
}

.selectPalletType span {
   display: block;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.inputPalletsAmount {
   width: 35% !important;
}
