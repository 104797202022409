.inputRow {
   display: flex;
   gap: 24px;
}

.banner {
   width: 100%;
}

.zipCode {
   flex: 1;
}

.city {
   flex: 3;
}

.inputsContainer,
.form,
.formContent {
   display: flex;
   flex-direction: column;
   gap: 24px;
}

.actions {
   display: flex;
   gap: 24px;
   justify-content: flex-end;
}

.formContent {
   position: relative;
}

.distributionHeader {
   margin: 24px 0 0;
   font-weight: 700;
   font-size: 16px;
   line-height: 22px;
   color: var(--primary-text);
}
