.tableRow {
   border-bottom: 1px solid #e6e8eb !important;
}

.cellWithoutArrow input::-webkit-outer-spin-button,
.cellWithoutArrow input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
.cellWithoutArrow input[type='number'] {
   -moz-appearance: textfield;
}

.input {
   display: flex;
   justify-content: flex-end;
}

.input {
   width: 100%;
}

.input > input {
   text-align: right;
}

.summaryCell {
   text-align: right;
   font-weight: 700;
}
