.list {
    width: 100%;
    margin-block: 0;
    padding: 8px 12px;
    background-color: #FBFBFB;
    border: 0.7px solid #DFDFE3;
    border-radius: 8px;
    list-style: none;
    box-sizing: border-box;
}

.moreButton {
    margin-top: 6px;
    height: auto;
    padding: 4px 8px;
    background-color: #EFEFF1;
    color: #3E3E4B;
}

.moreButton:hover {
    background-color: var(--secondary-gray);
}