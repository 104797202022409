.title {
   margin: 0 0 8px 0;
}

.subtitle {
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
   margin: 0;
}
