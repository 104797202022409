@value variables: "variables.css";
@value mobile-threshold-width from variables;

.infoText {
   max-width: 385px;
   margin-block: 0;
   font-size: 12px;
   line-height: 18px;
   letter-spacing: 0.001em;
   color: rgba(148, 148, 159, 1);
}

.input {
   margin-top: 24px;
   max-width: 350px;
   width: 100%;

   @media (max-width: mobile-threshold-width) {
      max-width: none;
   }
}
