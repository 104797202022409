@value variables: "variables.css";
@value first-table-shrink-width from variables;

.main {
   position: relative;
   height: calc(100vh - 72px);
   background-color: var(--gray-background-color);
   display: flex;
   flex-direction: column;
   align-items: center;
}

.authorshipInfo {
   position: absolute;
   bottom: 48px;
   left: 100px;
   font-weight: 400;
   font-size: 11px;
   line-height: 13px;
   color: var(--gray);
}

@media (max-width: first-table-shrink-width) {
   .main {
      height: calc(100vh - 52px);
      padding: 0 16px;
      justify-content: space-between;
   }

   .authorshipInfo {
      position: static;
      margin-bottom: 24px;
   }
}
