@value variables: "variables.css";
@value first-table-shrink-width from variables;

.container {
   width: 46%;
}

@media (max-width: first-table-shrink-width) {
   .container {
      width: inherit;
   }
}
