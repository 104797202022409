.header {
    margin: 48px auto 24px;
}

.searchInput {
    width: 315px !important;
    color: var(--gray-primary-text-color);
}

.statusLegend {
    width: 350px
}