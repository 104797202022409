.radioButton {
   box-sizing: border-box;
   height: 48px;
}

.radioButton input[type='radio']:checked {
   background-color: var(--primary-green) !important;
}

.radioButton label {
   color: var(--primary-text) !important;
}

.radioButton.buttonView.checked {
   border: 2px solid var(--primary-green) !important;
}

.radioButton.buttonView.checked label {
   font-weight: 700;
   color: var(--primary-green) !important;
}

.radioButton.buttonView:hover {
   border: 2px solid var(--primary-green);
}

.radioButton.buttonView.error {
   border: 2px solid var(--primary-red);
}

.radioButton.disabled {
   border-color: #a6a6b2 !important;
}

.radioButton.disabled label {
   color: #a6a6b2 !important;
}
