.listItem {
    padding-block: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EFEFF1;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.001em;
    color: var(--primary-text);
}

.listItem:last-of-type  {
    border-bottom: none;
}

.leftPartContainer {
    display: flex;
    align-items: center;
    gap: 16px;
}

.amount {
    box-sizing: border-box;
    width: 64px;
}

.palletsType {
    width: 190px
}

.actionVerb {
    width: 66px;
    margin-right: 10px;
}

.greyText {
    line-height: 16px;
    color: #94949F;
}

.boldText {
    font-weight: 700;
    line-height: 16px;
}

.multipleRowsContainer {
    display: flex;
    flex-direction: column;
}

.balanceAfter {
    width: 90px;
    display: flex;
    gap: 4px;
}

.positiveBalanceAfter {
    color: var(--primary-green);
}

.negativeBalanceAfter {
    color: #DB285C;
}