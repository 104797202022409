@value variables: "variables.css";
@value mobile-threshold-width from variables;

.submitBtn {
   margin: 12px 0 24px auto;
   z-index: 1;
}

@media (max-width: mobile-threshold-width) {
   .submitBtn {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      margin-bottom: 0 !important;
      border-radius: 0;
   }
}
