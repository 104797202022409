.modalContent {
   display: flex;
   flex-direction: column;
}
.modalContent button {
   align-self: flex-end;
   width: 150px;
}
.modalContent p {
   margin-top: 0;
   margin-bottom: 48px;
}
