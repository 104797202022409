div.row {
    margin-bottom: 0;
    padding: 12px 0;
}

div.row:last-child {
    margin-bottom: 12px;
}

.cell {
    display: flex;
    align-items: center;
    gap: 8px;
}

.cell > svg {
    color: #FCC100;
}