.tabInfoBadge {
   height: 22px;
   width: 22px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 20px;
   color: white;
   background-color: var(--primary-red);
}
