@value variables: "variables.css";
@value mobile-threshold-width from variables;

.mainContainer {
   padding: 48px 0 36px 0;
}

.bannersContainer.withAlertBanners {
   margin-bottom: 48px;
}

.banner {
   margin-bottom: 12px;
}

.banner:last-child {
   margin-bottom: 0;
}

.headerContainer {
   display: flex;
   flex-direction: column;
}

.header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 24px;
}

.header > div {
   display: flex;
}

.searchInput {
   width: 315px !important;
   color: var(--gray-primary-text-color);
}

.searchInput svg {
   top: 17px !important;
   width: 17px;
   height: 17px;
}

.searchInput input {
   height: 48px;
}

.searchBtn {
   margin-left: 24px;
   padding: 12px 32px;
   height: 48px !important;
}

.tabs {
   margin-bottom: 24px;
}

@media screen and (max-width: mobile-threshold-width) {
   .mainContainer {
      min-height: calc(100vh - 170px);
      padding: 0;
      width: 100vw;
   }

   .headerContainer {
      flex-direction: column-reverse;
   }

   .header {
      align-items: flex-start;
   }

   .searchInput input {
      border-top: none !important;
      border-left: none !important;
      border-radius: 0 !important;
   }

   .searchBtn {
      display: none !important;
   }

   .tableHideMobile {
      display: none;
   }
}
