.palletTotalPriceDivider {
   margin: 24px 0;
}

.palletTotalPriceContainer {
   width: 100%;
   display: flex;
   justify-content: space-between;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--primary-text);
}

.palletTotalPriceContainer .totalPrice {
   font-weight: 700;
   font-size: 18px;
}
