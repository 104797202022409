.bold {
   font-weight: 700;
}

.addressFirstLine,
.addressSecondLine {
   margin: 0;
   font-size: 14px;
   line-height: 24px;
}

.addressSecondLine {
   color: var(--secondary-text);
}
