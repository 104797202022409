@value variables: "variables.css";
@value mobile-threshold-width from variables;

.container {
   width: 590px;
   height: 238px;
   background-color: var(--light-gray);
   padding: 12px;
   border-radius: 8px;
   box-sizing: border-box;
}
.header {
   display: flex;
   align-items: center;
   margin-bottom: 12px;
}
.header h3 {
   margin: 0 0 0 13px;
   color: var(--primary-text);
   font-size: 14px;
   line-height: 24px;
}
.table {
   color: var(--primary-text);
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
   width: 100%;
   border-collapse: collapse;
}
.table tbody {
   background-color: white;
}
.table thead {
   background-color: var(--gray-table-header);
   text-align: left;
}
.table thead th {
   padding: 12px 50px 12px 8px;
}
.table tbody tr td {
   padding: 8px 40px 8px 8px;
}

@media screen and (max-width: mobile-threshold-width) {
   .container {
      width: 100%;
      height: auto;
   }
   .table {
      table-layout: fixed;
   }
}
