.container h3 {
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
   margin-top: -40px;
   margin-bottom: 48px;
   margin-left: -4px;
   max-width: 100%;
}
.modalContent {
   align-items: flex-start;
   margin: 0 auto 40px auto;
   text-align: center;
   position: relative;
}

.modalContent th:nth-child(2),
.modalContent th:nth-child(3),
.modalContent th:nth-child(4) {
   text-align: right;
   width: 70px;
}

.summaryFirstCell {
   text-align: left;
   font-weight: bold;
   padding-top: 25px !important;
}
.summaryCell {
   text-align: right;
   padding-top: 25px !important;
}
