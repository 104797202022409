@value variables: "variables.css";
@value first-table-shrink-width from variables;

.damageLossTypesContainer {
   display: flex;
   justify-content: space-between;
   margin-bottom: 48px;
}

.damageLossTypeCard {
   width: 100%;
   height: 192px;
   border-radius: 8px;
   border-color: #dfdfe3;
}

.damageLossTypeCard:first-child {
   margin-right: 24px;
}

.damageLossTypeCard.checkedCard {
   border-color: var(--primary-green);
   background-color: #f1f8f3;
}

.damageLossTypeCard h2 {
   font-weight: 400 !important;
   color: var(--gray-primary-text-color);
}

.buttonsContainer {
   display: flex;
   justify-content: flex-end;
}

@media (max-width: first-table-shrink-width) {
   .damageLossTypesContainer {
      margin-bottom: 0;
      flex-direction: column;
      align-items: center;
   }

   .damageLossTypeCard:first-child {
      margin-right: 0;
   }

   .damageLossTypeCard {
      margin-bottom: 24px;
      height: 168px;
      width: 95%;
   }

   .buttonsContainer {
      position: fixed;
      bottom: 0;
      left: 0;
   }

   .buttonsContainer {
      width: 100%;
   }

   .buttonsContainer button {
      width: 100%;
      border-radius: 0;
   }
}
