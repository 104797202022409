.tab {
   border-bottom: #e6e8eb 2px solid;
   width: fit-content;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 12px 24px;
   box-sizing: border-box;
   cursor: pointer;
   z-index: 1;
   transition: ease-in-out 0.2s;
}
.tab.active {
   border-bottom: #00af50 2px solid;
}
.tabText {
   font-weight: 700;
   font-size: 14px;
   line-height: 24px;
   color: #9ea3ad;
   transition: ease-in-out 0.2s;
}
.tab.active .tabText {
   color: #00af50;
}
.infoBadge {
   margin-left: 8px;
   padding: 0;
}
