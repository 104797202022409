.formContainer {
   height: calc(100% - 70px);
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.palletsContainer {
   display: flex;
   gap: 16px;
}
.sectionSubtitle {
   color: var(--secondary-text);
   margin: 4px 0 24px 0;
   font-size: 14px;
}
.sectionContainer h3 {
   margin-bottom: 0;
}
