.formContainer {
   min-height: calc(100vh - 180px);
   position: relative;
   margin-bottom: 50px;
}

.bannersContainer {
   margin: 48px 0 54px 0;
}

.banner {
   margin-bottom: 24px;
}
