@value variables: "variables.css";
@value mobile-threshold-width from variables;

.table {
   max-height: 600px;
   overflow: auto;
}

.tableHeader {
   position: sticky;
   top: 0;
   z-index: 1;
   background-color: white;
}

.tableRowWithTopFreeSpace td {
   padding-top: 36px;
}

.table td:first-of-type {
   padding-right: 0;
}

.table td {
   vertical-align: top;
}

.calendarTableCell > span {
   display: flex;
   align-items: center;
}

.calendarTableCell span span {
   margin-left: 12px;
}

.palletCell {
   padding: 0;
}

.palletRow td {
   padding-top: 0;
}

.palletAmountRow {
   display: flex;
   justify-content: flex-end;
   padding-right: 30px;
}

.palletAmountRow td {
   padding: 0;
}

.remainingPalletsToBeSendBanner {
   margin-top: 24px;
   align-items: baseline;
}

.allPalletsSendBanner {
   margin-top: 48px;
}

.toolbarContainer {
   display: flex;
   gap: 12px;
   margin-bottom: 24px;
}

.toolbarContainer > div {
   display: flex;
   gap: 12px;
}

.searchInput svg {
   top: 17px !important;
   width: 17px;
   height: 17px;
}

@media (max-width: mobile-threshold-width) {
   .toolbarContainer {
      flex-direction: column-reverse;
   }

   .toolbarContainer > div {
      flex-direction: column;
   }

   .searchInput {
      width: 100%;
   }
}
