.inputsContainer {
   display: flex;
   flex-direction: column;
   gap: 24px;
}

.inputsRow {
   display: flex;
   justify-content: space-between;
   align-items: flex-end;
}

.actions {
   display: flex;
   gap: 24px;
   justify-content: flex-end;
}

.modalContent {
   margin: 0 auto 40px auto;
   text-align: center;
   position: relative;
}

.selectContainer,
.inputContainer {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   flex-basis: 40%;
}

.select {
   width: 100%;
}

.inputContainer > * {
   width: 100%;
   text-align: left;
}

.arrow {
   display: flex;
   height: 48px;
   align-items: center;
}

.banner {
   width: 100%;
   margin-bottom: 24px;
}
