.card {
   width: 500px;
   padding: 60px 24px;
   box-sizing: border-box;
}

.cardContent {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.cardDisabled {
   opacity: 0.6;
   pointer-events: none;
   z-index: 99;
   background-color: #efeff1;
}

.cardText {
   margin: 4px 0 0 0;
   text-align: center;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-dark-gray);
   width: 390px;
}

.addressSelect {
   width: 450px;
}
