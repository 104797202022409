.modalContent {
   margin-bottom: 48px;
   padding: 5px;
   max-height: 600px;
   overflow: auto;
}

.formContentContainer {
   width: 100%;
}

.commercialNetworkTypeContainer {
   margin-bottom: 36px;
}

.commercialNetworkTypeContainer p {
   font-size: 14px;
   line-height: 24px;
}

.commercialNetworkTypeContainer p:first-child {
   margin: 0 0 16px 0;
   color: var(--secondary-text);
}

.commercialNetworkTypeContainer p:last-child {
   margin: 0;
   color: var(--primary-text);
}

.storagePointTypeSelect {
   margin-bottom: 24px;
}

.storagePointTypeSelect > div {
   width: 300px;
}

.input {
   margin-bottom: 24px;
}

.inputsGroup {
   width: 100%;
   display: flex;
   justify-content: space-between;
   margin-bottom: 24px;
}

.inputsGroup:last-of-type,
.input:last-of-type {
   margin-bottom: 0;
}

.zipCodeInput {
   width: 150px;
   margin-right: 24px;
}

.cityInput {
   width: 360px;
}
