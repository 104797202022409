@value variables: "variables.css";
@value mobile-threshold-width from variables;

.cardContainer {
   position: relative;
   margin-right: 16px;
   margin-bottom: 16px;
}
.cardSmall {
   padding: 24px;
   width: 260px;
   border-radius: 8px;
}
.cardInactive {
   pointer-events: none;
}
.disabledContainerActive {
   background-color: #efeff1;
   height: 100%;
   width: 100%;
   position: absolute;
   opacity: 0.6;
   pointer-events: none;
   z-index: 2;
   border-radius: 8px;
}
.smCardBottomContent {
   width: 100%;
}
.radioRow {
   margin-top: 24px;
   display: flex;
   align-items: center;
}
.smInput label {
   top: -15px;
   width: 115px;
   font-size: 11px;
   line-height: 13px;
   color: var(--gray);
   position: absolute;
}
.radioContainer {
   margin-top: 44px;
}
.smInput input {
   padding: 0 15px;
}
.smCardBottomContent p {
   margin: 0;
   font-size: 14px;
   line-height: 24px;
   color: var(--primary-text);
}
.smCardBottomContent > p {
   margin: 4px 0 0 0;
}
.smCardBottomContent > p:first-of-type {
   color: var(--secondary-text);
}
.smInput input:hover {
   border: 1px solid var(--primary-green);
}
.smInput input:focus {
   box-shadow: 0px 0px 0px 2px rgba(148, 247, 163, 0.8);
   border: 1px solid var(--primary-green);
}
.smInput.error input:focus {
   border: 1px solid var(--primary-red);
   box-shadow: none;
}
.radioRowError {
   margin-bottom: 45px;
}
.smInput span {
   width: 170px;
   position: absolute;
   bottom: -25px;
}
.radioRow p {
   margin-left: 8px;
}
.radioInput {
   width: 20px;
   height: 20px;
   accent-color: #0b9147;
   cursor: pointer;
   margin-right: 12px;
}
.cardPalletImage {
   margin-top: 25px;
}
.clearBtnSection {
   margin-top: 24px;
}
.divider {
   height: 2px !important;
}
.clearBtnSection .clearBtn {
   height: auto;
   margin: 24px auto 0 auto;
   padding: 0;
}
@media screen and (max-width: mobile-threshold-width) {
   .cardContainer {
      margin-right: 0;
   }
   .cardSmall {
      margin: 16px 0;
      width: 100% !important;
      padding-bottom: 24px !important;
   }
   .smInput span {
      position: relative;
      bottom: 0;
   }
   .radioRow {
      align-items: baseline;
   }
   .radioRowError {
      margin-bottom: 25px;
   }
   .smInput label {
      width: 135px;
   }
}
