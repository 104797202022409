.container {
   display: flex;
   justify-content: flex-end;
}

.container .button {
   height: fit-content;
   padding: 0;
   font-weight: 700;
   line-height: 24px;
}
