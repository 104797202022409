@value variables: "variables.css";
@value first-table-shrink-width from variables;

.header {
   margin-bottom: 48px;
}

.buttonsContainer {
   display: flex;
   align-items: center;
   gap: 24px;
}

.heading {
   margin: 0;
   font-size: 28px;
   line-height: 35px;
   color: var(--primary-text);
}

@media (max-width: first-table-shrink-width) {
   .header {
      margin-bottom: 24px;
   }

   .buttonsContainer {
      width: 100%;
      flex-direction: column;
   }

   .buttonsContainer button {
      width: 100%;
   }

   .buttonsContainer button:first-of-type {
      margin: 0 0 16px 0;
   }
}
