@value variables: "variables.css";
@value first-table-shrink-width from variables;

.table {
   margin-bottom: 12px;
}

.tableHeader th {
   padding: 12px 24px 12px 0;
   font-weight: 700;
   color: var(--gray-primary-text-color);
}

.tableHeader th:last-child {
   padding-right: 0;
}

@media (max-width: first-table-shrink-width) {
   .addPalletTypeBtn {
      margin-bottom: 30px;
   }
}
