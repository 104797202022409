@value variables: "variables.css";
@value first-table-shrink-width from variables;

.buttonsContainer {
   display: flex;
   justify-content: flex-end;
   margin-top: 72px;
}

.buttonsContainer button:first-child {
   margin-right: 12px;
   padding: 10px 24px;
}

@media (max-width: first-table-shrink-width) {
   .buttonsContainer {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      flex-direction: column-reverse;
   }

   .buttonsContainer button {
      border-radius: 0;
   }
}
