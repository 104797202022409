.tableRow {
   border-bottom: 1px solid var(--secondary-gray);
}
.tableRow > td:first-of-type {
   padding-left: 24px;
}

.tableCell {
   padding: 12px 24px;
}

.tableCell:first-of-type {
   padding-left: 24px;
}
