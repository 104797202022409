.mainContainer {
   width: 24px;
   display: flex;
   align-items: center;
   position: relative;
}

.tooltipContainer {
   display: none;
   position: absolute;
   top: -5px;
   left: 30px;
   padding: 6px 12px;
   border-radius: 4px;
   background-color: var(--gray-primary-text-color);
   color: white;
   line-height: 24px;
   white-space: nowrap;
   z-index: 2;
}

.mainContainer:hover .tooltipContainer {
   display: inline;
}

.tooltipContainer.displayTooltip {
   display: inline;
}
