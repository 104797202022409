:root {
   --tooltip-margin: 35px;
   --tooltip-margin-right: 10px;
   --tooltip-arrow-size: 6px;
}

.toolTipWrapper {
   display: inline-block;
   position: relative;
}

.toolTipContent {
   position: absolute;
   border-radius: 4px;
   left: 50%;
   transform: translateX(-50%);
   padding: 4px 8px;
   color: white;
   background: #50505e;
   font-size: 14px;
   font-family: 'Source Sans Pro';
   line-height: 24px;
   z-index: 100;
   white-space: nowrap;
   font-weight: 400;
   display: none;
}

.toolTipContent::before {
   content: ' ';
   left: 50%;
   border: solid transparent;
   height: 0;
   width: 0;
   position: absolute;
   pointer-events: none;
   border-width: var(--tooltip-arrow-size);
   margin-left: calc(var(--tooltip-arrow-size) * -1);
   border-top-color: #50505e;
}

.toolTipWrapper:hover .toolTipContent.visible {
   display: inline;
}

.toolTipContent.top {
   top: calc(var(--tooltip-margin) * -1);
}

.toolTipContent.top::before {
   top: 100%;
}

.toolTipContent.right {
   left: calc(100% + var(--tooltip-margin-right));
   top: 50%;
   transform: translateX(0) translateY(-50%);
}

.toolTipContent.right::before {
   left: calc(var(--tooltip-arrow-size) * -1);
   top: 50%;
   transform: translateX(0) translateY(-50%);
   border-right-color: #50505e;
   border-top-color: transparent;
}

.toolTipContent.bottom {
   bottom: calc(var(--tooltip-margin) * -1);
}
.toolTipContent.bottom::before {
   bottom: 100%;
   border-bottom-color: #50505e;
   border-top-color: transparent;
}

.toolTipContent.left {
   left: auto;
   right: calc(100% + var(--tooltip-margin-right));
   top: 50%;
   transform: translateX(0) translateY(-50%);
}
.toolTipContent.left::before {
   left: auto;
   right: calc(var(--tooltip-arrow-size) * -2);
   top: 50%;
   transform: translateX(0) translateY(-50%);
   border-top-color: transparent;
   border-left-color: #50505e;
}
