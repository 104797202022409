.autocompleteContainer {
   width: 400px;
}

.autocompleteLabel {
   margin-bottom: 4px;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: #808089;
}

.autocompleteInput {
   height: 48px;
   cursor: pointer;
}

.autocompleteInput:hover {
   border: 2px solid var(--primary-green);
}

.autocompleteInput:focus {
   box-shadow: 0px 0px 0px 2px rgba(148, 247, 163, 0.8);
   border: 2px solid var(--primary-green);
}

.autocompleteInput.error {
   border: 1px solid var(--primary-red);
}

.autocompleteInput.error:focus {
   border: 1px solid var(--primary-red);
   box-shadow: none;
}

.autocompleteDropdownWrapper {
   max-height: 300px;
}

.option {
   padding: 12.5px 20px;
   cursor: pointer;
}

.option:hover {
   padding: 12.5px 20px;
   font-weight: 700;
   background-color: #e3fde5;
}

.mainText,
.additionalText {
   font-size: 14px;
   line-height: 24px;
}

.option:hover .mainText,
.option:hover .additionalText {
   color: var(--primary-green);
}

.mainText {
   color: var(--primary-text);
}

.additionalText {
   color: #a6a6b2;
}

.errorText {
   margin: 4px 0 0 0;
   font-size: 14px;
   line-height: 24px;
   color: var(--primary-red);
}
