@value variables: "variables.css";
@value first-table-shrink-width from variables;

.modal :global .mantine-Modal-modal {
   max-width: 600px;
   width: 100%;
}

.modal :global .mantine-Modal-header {
   margin-bottom: 50px;
}

.modal :global .mantine-Modal-body {
   padding: 0;
}

.contentContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.contentContainer svg {
   margin-bottom: 30px;
}

.contentContainer h3 {
   margin: 0 0 12px 0;
   font-size: 22px;
   line-height: 27px;
   text-align: center;
   color: var(--gray-primary-text-color);
}

.contentContainer p {
   margin: 0;
   font-size: 14px;
   line-height: 24px;
   text-align: center;
   color: var(--gray-primary-text-color);
}

.contentContainer p:last-of-type {
   margin: 0 0 48px 0;
}

.contentContainer ul {
   max-height: 100px;
   margin: 0 0 20px 0;
   padding: 0 10px 0 30px;
   overflow-y: auto;
}

.contentContainer ul li {
   font-size: 14px;
   line-height: 24px;
   color: var(--gray-primary-text-color);
}

.contentContainer ul li span {
   font-weight: 700;
}

.btnContainer {
   display: flex;
   justify-content: center;
   width: 100%;
}

@media (max-width: first-table-shrink-width) {
   .modal :global .mantine-Modal-modal {
      max-width: none;
   }

   .modal :global .mantine-Modal-header {
      margin-bottom: 100px;
   }

   .modal :global .mantine-Modal-body {
      max-height: calc(100vh - 180px);
   }

   .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
   }

   .btnContainer {
      flex-direction: column;
   }
}
