@value variables: "variables.css";
@value first-table-shrink-width from variables;

.container {
   padding: 48px;
   display: flex;
   justify-content: space-between;
   border-radius: 8px;
   border: 1px solid var(--gray-border);
}

.summaryItem {
   margin-left: 20px;
   padding-left: 24px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   border-left: 1px solid var(--gray-border);
}

.summaryItem:first-of-type {
   margin-left: 0;
   padding-left: 0;
   border: none;
}

.summaryItemTitle {
   margin: 0 0 24px 0;
   font-size: 14px;
   line-height: 24px;
   color: #94949f;
}

.summaryItemAmount {
   margin: 0;
   font-size: 34px;
   line-height: 42px;
   font-weight: 700;
   color: var(--primary-text);
}

@media (max-width: first-table-shrink-width) {
   .container {
      padding: 24px;
      flex-direction: column;
   }

   .summaryItem {
      margin: 32px 0 0 0;
      padding: 32px 0 0 0;
      border-left: none;
      border-top: 1px solid var(--gray-border);
   }

   .summaryItem:first-of-type {
      margin-top: 0;
      padding: 0;
   }

   .summaryItemTitle {
      margin-bottom: 16px;
   }
}
