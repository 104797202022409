.containerPadding {
   padding: 24px;
}

.storagesContainer {
   display: flex;
   flex-direction: column;
   gap: 24px;
   margin-bottom: 24px;
}

.listContainer {
   display: flex;
   flex-direction: column;
   gap: 24px;
}

.search {
   width: 315px;
}

.loaderContainer {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 300px;
}

.loader {
   padding: 10px 0;
}
