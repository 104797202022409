.modalContent {
   margin-bottom: 45px;
}

.modalContent p {
   max-width: 360px;
   margin: 0;
   text-align: center;
   line-height: 24px;
   color: var(--primary-text);
}
