.tableRow {
   position: relative;
   border: 0;
}

.tableCell {
   padding: 12px 24px 12px 0;
}

.tableCell:last-child {
   padding-right: 0;
}

.selectPalletType {
   width: 270px;
}

.selectPalletType > div {
   width: 100%;
}

.palletsAmountInput {
   width: 120px;
}

.palletsAmountInput input {
   height: 48px;
}

.tableRow .removeTableRowBtn {
   width: 22px;
   padding: 0;
   color: var(--gray-primary-text-color);
}
