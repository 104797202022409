@value variables: "variables.css";
@value mobile-threshold-width from variables;

.container {
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

@media (max-width: mobile-threshold-width) {
   .container {
      height: calc(100vh - 160px);
   }
}
