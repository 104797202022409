.container h3 {
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
   margin-top: -40px;
   margin-bottom: 48px;
   margin-left: -4px;
   max-width: 100%;
}

.modalContent {
   align-items: flex-start;
   margin: 0 auto 40px auto;
   text-align: center;
   position: relative;
}
.table th {
   text-align: right;
}
.table th:first-child {
   text-align: left;
}

.table th:nth-child(4) {
   padding-right: 115px;
   width: 70px;
}

.table th:last-child {
   width: 70px;
}
