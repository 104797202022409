.list {
    margin-block: 0;
    padding: 16px;
    list-style: none;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 24px 0 #94949F1F;
}

.divider {
    margin-block: 8px;
}