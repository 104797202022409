.labelText,
.valueText {
   font-size: 14px;
   line-height: 24px;
   letter-spacing: 0.001em;
}

.labelText {
   display: block;
   color: rgba(128, 128, 137, 1);
}

.valueText {
   color: rgba(74, 74, 79, 1);
}
