.freeOrderInfo {
   width: calc(100% - 48px);
   margin: 24px 0 48px 24px;
   align-items: flex-start;
}

.freeOrderInfo p {
   margin: 0;
}

.btnsContainer {
   display: flex;
   float: right;
   padding-bottom: 20px;
}
