.selectContainer {
   color: var(--gray-primary-text-color);
}

.select {
   border-radius: 4px;
}

.select:hover {
   border: 2px solid var(--primary-green);
}

.select.error {
   border: 2px solid var(--primary-red);
   box-shadow: none;
}

.optionsContainer {
   overflow: auto;
   z-index: 2;
   border-radius: 8px;
}
