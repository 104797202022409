@value variables: "variables.css";
@value mobile-threshold-width from variables;

.changePriceBtn {
   margin-bottom: 24px;
}

.cardsContainerSm {
   display: flex;
   flex-wrap: wrap;
   margin-bottom: 24px;
}

.cardsContainerSm .cardSmall {
   padding-top: 30px;
}

@media screen and (max-width: mobile-threshold-width) {
   .cardsContainerSm {
      flex-direction: column;
   }
}
