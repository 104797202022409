@value variables: "variables.css";
@value first-table-shrink-width from variables;

.pageContainer {
   padding: 48px 0 24px 0;
}

.pageLoader {
   height: calc(100vh - 250px);
}

@media (max-width: first-table-shrink-width) {
   .pageContainer {
      padding: 0 16px;
   }
}
