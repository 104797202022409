.badge {
   padding: 4px 12px !important;
   line-height: 24px;
}

.waiting {
   color: var(--yellow-warning-text);
   background-color: var(--yellow-warning-background);
}

.inProgress {
   color: var(--blue-warning-text);
   background-color: var(--blue-warning-background);
}

.blue {
   color: var(--dark-blue-warning-text);
   background-color: var(--purple-warning-background);
}

.completed {
   color: var(--dark-green);
   background-color: var(--green-warning-background);
}

.red {
   color: var(--dark-red);
   background-color: var(--red-warning-background);
}

.duringBilling {
   color: var(--primary-text);
   background-color: #dfdfe3;
}

.toPay {
   color: #e57003;
   background-color: #fad9bb;
}

.new,
.free {
   padding: 2px 6px 2px 6px !important;
   border-radius: 20px !important;
   font-size: 11px !important;
   line-height: 18px;
   color: white;
}

.new {
   background-color: var(--primary-red);
}

.free {
   background-color: #a6a6b2;
}

.positive {
   color: var(--primary-green);
   background-color: #E4F1E6;
}

.negative {
   color: #DB285C;
   background-color: var(--light-red);
}
