@value variables: "variables.css";
@value mobile-threshold-width from variables;

.placeOrderContainer {
   height: calc(100% - 80px);
}

.banner {
   margin: 48px 0 24px 0;
}

.stepsContainer {
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;
   margin: 60px 0;
}
.chevron {
   margin: 0 45px;
}

.loaderContainer {
   width: 100%;
   max-height: 650px;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px 0;
   border-radius: 16px;
   background-color: white;
}

@media screen and (max-width: mobile-threshold-width) {
   .placeOrderContainer {
      padding: 0 16px;
   }
   .cancelBtn,
   .submitBtn {
      position: fixed;
      bottom: 0;
      width: 50%;
      border-radius: 0;
   }
   .submitBtn {
      right: 0;
   }
   .cancelBtn {
      background-color: white !important;
      box-sizing: border-box;
      left: 0;
   }
   .stepsContainer {
      justify-content: flex-start;

      margin: 36px 0;
   }
   .hideTextMobile span {
      display: none;
   }
   .chevron {
      margin: 0 30px;
   }
}
