.whiteCard {
   padding: 24px;
   margin-bottom: 24px;
}
.banner {
   margin-bottom: 24px;
}
.header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 24px;
}
.header h3 {
   margin: 0;
   font-size: 18px;
   line-height: 24px;
}
.right {
   display: flex;
}

.datePicker {
   width: 200px;
}

.innerContainer {
   position: relative;
   padding: 0;
   margin-bottom: 24px;
   background-color: white;
   box-shadow: none;
   border: 1px solid var(--gray-border);
   border-radius: 8px;
}

.table tbody tr:last-child {
   border-bottom: none !important;
}

.table th:first-child {
   width: 40%;
   padding-left: 24px;
   padding-top: 24px;
}
.table th:last-child {
   padding-right: 24px;
}
.table td {
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   cursor: pointer;
   padding: 12px 24px !important;
}

.table th {
   padding-right: 0;
   min-width: 74px;
   white-space: nowrap;
}

.table th:nth-child(4) {
   padding-right: 50px;
}

.table td:nth-child(2) {
   font-weight: 900;
}

.detailsBtn {
   padding: 12px 24px 12px 0px !important;
}
.loader {
   position: absolute;
   left: 50%;
   transform: translateY(50%);
}

.innerContainerSendPallets {
   padding: 48px;
   margin-bottom: 24px;
   background-color: white;
   box-shadow: none;
   border: 1px solid var(--gray-border);
   border-radius: 8px;
}

.sendingContainerEmpty {
   width: 148px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: center;
   margin: 0 auto;
   font-weight: 700;
   font-size: 14px;
   line-height: 20px;
   color: #a6a6b2;
}
.sendingContainerEmpty > svg {
   width: 48px;
   height: 48px;
   margin-bottom: 8px;
}

.tableOrders th:nth-child(7) {
   width: 117px;
}
.tableOrders th:nth-child(8) {
   text-align: right;
}

.tableOrders td:first-child {
   padding: 12px 0 12px 24px;
}

.innerContainer {
   overflow: auto;
   max-height: 580px;
   background-color: white;
   border-radius: 8px;
   box-shadow: 0px 2px 24px rgba(148, 148, 159, 0.12);
}

.whiteCard .innerContainer .tableOrders th:last-child {
   text-align: left;
}

.tableOrders th:first-of-type {
   padding-left: 24px;
}
