.modalContent {
   margin-bottom: 72px;
}

.modalContent h3 {
   max-width: 400px;
}

.descriptionContainer {
   max-width: 400px;
   text-align: center;
}

.plainText {
   margin: 0 0 25px 0;
   color: var(--primary-text);
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
}

.boldBlackText {
   margin: 0;
   color: var(--primary-text);
   font-weight: 700;
   font-size: 14px;
   line-height: 24px;
}
