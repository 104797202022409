.container {
   padding: 12px 24px 12px 24px;
   border: 1px solid var(--primary-green);
   border-radius: 4px;
   background-color: transparent;
   color: var(--primary-green);
   cursor: pointer;
}

.container:hover {
   background-color: var(--primary-50);
}

.container input {
   display: none;
}

.container div {
   display: flex;
   align-items: center;
}

.container span:first-of-type {
   margin-right: 12px;
}

.container span:last-of-type {
   font-weight: 700;
   font-size: 14px;
   line-height: 24px;
}
