.selectContainer {
   position: relative;
}
.selectContainer.fullWidth,
.selectContainer.fullWidth > div {
   width: 100%;
}
.selectContainer p {
   font-size: 14px;
   line-height: 24px;
   color: #9ea3ad;
}
.selectLabel {
   margin: 0 0 4px 0;
}
.select {
   width: 250px;
   height: 48px;
   border: 1px solid #c7cad0;
   border-radius: 2px;
   cursor: pointer;
   padding: 12px 20px;
   box-sizing: border-box;
   font-size: 14px;
   line-height: 24px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   color: #39414f;
   background-color: white;
   transition: ease-in-out 0.1s;
}
.select:hover {
   border: 1px solid #009653;
}
.select.expanded {
   box-shadow: 0px 0px 0px 2px rgba(148, 247, 163, 0.8);
   border: 1px solid #009653;
}
.select.error {
   border: 1px solid #ff385f;
}
.select.disabled {
   background-color: #e6e8eb;
   color: #9ea3ad;
}
.select.disabled svg {
   fill: #9ea3ad;
}
.selectLeftContent {
   width: 90%;
   display: flex;
   align-items: center;
}
.selectLeftContent svg {
   margin-right: 15px;
}
.options {
   display: none;
}
.options.expanded {
   max-height: 300px;
   overflow: auto;
   display: flex;
   flex-direction: column;
   position: absolute;
   top: 50px;
   padding: 8px;
   box-sizing: border-box;
   z-index: 99;
   border-radius: 8px;
   background-color: white;
   box-shadow: 0px 21px 9px rgba(17, 17, 17, 0.01), 0px 12px 7px rgba(17, 17, 17, 0.05),
      0px 5px 5px rgba(17, 17, 17, 0.09), 0px 1px 3px rgba(17, 17, 17, 0.1),
      0px 0px 0px rgba(17, 17, 17, 0.1);
}
.options.expanded.withPortal {
   z-index: 201;
}
.options.expanded.withLabel {
   top: 78px;
}
.options.withPortal {
   position: fixed;
}
.filterInput {
   width: 100%;
   margin: 8px 0;
}
.filterInput input {
   height: 48px;
}
.group > span {
   padding: 4px 12px;
   font-weight: 700;
   font-size: 14px;
   line-height: 24px;
   color: #4a4a4f;
}
.group:not(:first-child) {
   border-top: 1px solid #c7cad0;
   padding-top: 8px;
}
.noOptionsMessage {
   font-size: 14px;
   line-height: 24px;
   padding: 12px;
   box-sizing: border-box;
   background-color: white;
   color: #39414f;
   display: flex;
   justify-content: flex-start;
   transition: ease-in-out 0.2s;
}
.noOptionsMessage span {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
}
.option {
   cursor: pointer;
   font-size: 14px;
   line-height: 24px;
   padding: 12px;
   box-sizing: border-box;
   background-color: white;
   color: #39414f;
   display: flex;
   justify-content: flex-start;
   transition: ease-in-out 0.2s;
}
.option:hover {
   background-color: #e3fde5;
   color: #00af50;
   font-weight: 700;
}
.option.selected {
   font-weight: 700;
   color: #00af50;
   background-color: #e3fde5;
}
.option span,
.selectLeftContent span {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
}
.dropdownBottomSection {
   padding: 16px 12px 8px 12px;
   border-top: 1px solid #c7cad0;
}
.helperText {
   margin: 4px 0 0 0;
   z-index: 0;
   position: relative;
}
.helperText.error {
   color: #ff385f;
}
