.title {
   font-weight: 700;
   font-size: 12px;
   line-height: 18px;
   letter-spacing: 0.001em;
   color: #a6a6b2;
}

.palletsNumberInfoContainer {
   display: flex;
   gap: 2px;
   align-items: center;
}

.palletsNumber {
}

.palletsNumber.summary {
   font-weight: 700;
}

.palletsDifference {
   display: flex;
   align-items: center;
   border-radius: 2px;
   padding-inline: 4px;
   background-color: #ffded7;
   color: #db285c;
   font-weight: 600;
   font-size: 12px;
   line-height: 18px;
}

.palletsDifference.positive {
   background-color: #e4f1e6;
   color: var(--primary-green);
}
