.tableContainer {
   margin-top: 24px;
}
.tableContainer table th:first-of-type,
.tableContainer table td:first-of-type {
   padding-left: 32px;
}
.tableContainer table tr {
   border-bottom: 1px solid var(--gray-border);
}
.blockedRow {
   display: flex;
   align-items: center;
}
.blockedRow svg {
   margin-right: 10px;
}
.noResultsContainer {
   padding: 165px 0;
   color: var(--gray-section-subtitle);
}
.noResultsContainer p {
   font-weight: bold;
   font-size: 14px;
}
