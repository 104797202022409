@value variables: "variables.css";
@value first-table-shrink-width from variables;

.modal :global .mantine-Modal-header {
   margin-bottom: 50px;
}

.modal :global .mantine-Modal-body {
   padding: 0;
}

.contentContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.contentContainer h3 {
   margin: 0 0 12px 0;
   font-size: 22px;
   line-height: 27px;
   text-align: center;
   color: var(--gray-primary-text-color);
}

.contentContainer p {
   margin: 0 0 80px 0;
   font-size: 14px;
   line-height: 24px;
   text-align: center;
   color: var(--gray-primary-text-color);
}

.btnsContainer {
   display: flex;
   justify-content: flex-end;
   width: 100%;
}

.btnsContainer button:first-child {
   margin-right: 8px;
}

@media (max-width: first-table-shrink-width) {
   .modal :global .mantine-Modal-header {
      margin-bottom: 100px;
   }

   .modal :global .mantine-Modal-body {
      max-height: calc(100vh - 180px);
   }

   .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
   }

   .btnsContainer {
      flex-direction: column-reverse;
   }

   .btnsContainer button:first-child {
      margin: 8px 0 0 0;
   }
}
