.palletContainer {
   width: 278px;
   height: 176px;
   box-sizing: border-box;
   padding: 24px;
   border: 1px solid var(--gray-border);
   border-radius: 8px;
   cursor: pointer;
   position: relative;
}
.palletContainerActive {
   background-color: var(--light-green-background);
   border: 1px solid var(--primary-green);
}
.palletContainer h3 {
   margin-bottom: 0;
   font-size: 18px;
   line-height: 24px;
}
.palletContainer p {
   margin-top: 4px;
   font-size: 14px;
   line-height: 24px;
}
.palletContainer img {
   margin-top: 15px;
}
.checkIcon {
   position: absolute;
   top: 24px;
   right: 24px;
}
.palletContainerDisabled {
   opacity: 0.5;
   background-color: var(--gray-upload-images-btn);
}
