.mainContainer {
   margin-top: 48px;
}
.banners {
   margin-bottom: 48px;
}
.banner {
   margin-bottom: 12px;
}
.clientHeader {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.clientHeader > div {
   display: flex;
   align-items: center;
   gap: 24px;
}
.clientBasicInfo > h2 {
   font-weight: 700;
   font-size: 28px;
   line-height: 35px;
   margin: 0;
}
.clientBasicInfo > span {
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   letter-spacing: 0.001em;
   color: var(--secondary-text);
}
.tabsContainer {
   display: flex;
   margin-top: 32px;
   width: 100%;
}
.tabsContainer div {
   cursor: pointer;
}
.loaderContainer {
   display: flex;
   width: 100%;
   height: 300px;
   justify-content: center;
   align-items: center;
}
