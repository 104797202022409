.container {
   border: 1px dashed #bbbbc3;
   border-radius: 8px;
   background-color: #fbfbfb;
   padding: 24px 0;
}

.content {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   max-width: 350px;
   width: 100%;
   margin: 0 auto;
}
