.modalContent {
   margin-bottom: 48px;
}

.text {
   margin: 0;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--primary-text);
}

.text:first-of-type {
   margin-bottom: 24px;
}
