@value variables: "variables.css";
@value first-table-shrink-width from variables;

.bannersContainer {
   margin-top: 72px;
}

.errorBanner {
   margin-bottom: 12px;
}

.errorStepFooter {
   margin-top: 48px;
}

.loader {
   width: 100%;
   height: 344px;
   display: flex;
   justify-content: center;
   align-items: center;
}

@media (max-width: first-table-shrink-width) {
   .lostDamagedPalletsContainer {
      height: calc(100vh - 200px);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
   }

   .bannersContainer {
      margin: 10px 0 24px 0;
   }

   .infoBanner {
      margin-bottom: 12px;
   }

   .additionalBannerMargin {
      margin-bottom: 24px;
   }
}
