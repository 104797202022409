.container {
   height: 50px;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
}

.amountInfo {
   font-size: 14px;
   line-height: 24px;
   color: var(--primary-text);
}

p.amountInfo {
   margin: 0;
}
