.dropzone {
   margin-bottom: 10px;
}

.excelUploadBtn {
   margin-bottom: 12px;
}

.description {
   margin: 0;
   text-align: center;
   font-size: 14px;
   line-height: 24px;
   color: var(--gray-section-title);
}
