.container {
   display: flex;
}

.column:first-child {
   margin-right: 160px;
}

.column p:first-of-type {
   margin: 0 0 24px 0;
   font-weight: 700;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
}

.infoBlock {
   margin-bottom: 24px;
}

.infoBlock p:first-of-type {
   margin: 0 0 4px 0;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
}

.infoBlock p:last-of-type {
   margin: 0;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--primary-text);
}

.infoBlocksContainer {
   display: flex;
}

.infoBlocksContainer .infoBlock {
   margin-bottom: 0;
}

.infoBlocksContainer .infoBlock:first-child {
   margin-right: 48px;
}
