.container {
   display: flex;
   align-items: center;
   gap: 8px;
   padding: 4px 16px;
   border-radius: 6px;
   border: 1px solid #dfdfe3;
   background-color: #fbfbfb;
}

.container .divider {
   height: 30px;
   background-color: #dfdfe3;
}
