@value variables: "variables.css";
@value mobile-threshold-width from variables;

.container {
   display: flex;
   max-width: 450px;
   width: 100%;
   justify-content: space-between;
   color: var(--primary-text);
}

.container p {
   margin: 0 0 4px 0;
   font-size: 14px;
   line-height: 24px;
}

.container.isFree p {
   font-weight: 700;
   color: var(--primary-green);
}

@media screen and (max-width: mobile-threshold-width) {
   .container {
      width: 100%;
   }

   .container p:first-child {
      margin-right: 10px;
   }

   .container p:last-child {
      flex-shrink: 0;
   }
}
