.text {
   margin: 0 0 48px 0;
   font-size: 14px;
   line-height: 24px;
}
.deleteModalContent {
   display: flex;
   flex-direction: column;
   align-items: center;
   color: var(--primary-text);
   font-family: 'Source Sans Pro', sans-serif;
}
.deleteModalContent strong {
   margin-bottom: 22px;
   font-size: 14px;
   line-height: 24px;
}
.deleteModalContent p {
   margin: 12px 0 24px 0;
   font-size: 14px;
   line-height: 24px;
}
