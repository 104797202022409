.headerLeft {
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-top: 5px;
}
.detailsHeader {
   display: flex;
   margin: 62px 0 32px 0;
   justify-content: space-between;
   align-items: flex-start;
}
.detailsHeader p {
   margin: 0;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
}
.headerLeft h2 {
   color: var(--primary-text);
   font-size: 28px;
   line-height: 35px;
   margin: 0 12px 0 0;
}
.orderNumberContainer {
   display: flex;
   align-items: center;
   margin-bottom: 10px;
}
.banner {
   margin-bottom: 24px;
}
.tabsSection {
   margin-bottom: 24px;
   position: relative;
}
.tabsContainer {
   display: flex;
   z-index: 1;
   position: relative;
   margin: 24px 0;
}
.tabsContainer div {
   cursor: pointer;
}
.tabsBorder {
   position: absolute;
   height: 2px !important;
   bottom: 0;
}
.optionsContainer {
   width: 48px;
   height: 48px;
}
.optionsContainer.optionsContainerActive {
   background-color: var(--light-green-2);
   border-radius: 4px;
}
.summaryContainer {
   margin: 50px 0 120px 0;
   display: flex;
   flex-direction: column;
   width: 100%;
   align-items: flex-end;
}
.summaryContainer p {
   margin: 0;
   display: flex;
   align-items: center;
   font-weight: 700;
}
.summaryContainer p span:first-of-type {
   font-size: 18px;
   line-height: 24px;
   margin-right: 48px;
}
.summaryContainer p span:last-of-type {
   font-size: 28px;
   line-height: 35px;
}
.buttonsContainer {
   width: calc(100% - 248px - 15px);
   display: flex;
   justify-content: flex-end;
   position: fixed;
   bottom: 0;
   right: 15px;
   padding: 12px 48px 12px 0;
   box-sizing: border-box;
   gap: 12px;
   background-color: var(--light-gray);
   z-index: 3;
}
.loaderContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
}
