@value variables: "variables.css";
@value mobile-threshold-width from variables;

.contentContainer {
   margin-bottom: 40px;
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
}

.contentContainer.leftSide {
   align-items: flex-start;
}

.icon {
   width: 48px;
   height: 48px;
   stroke-width: 1.5;
   margin-bottom: 20px;
}

.icon.green {
   color: var(--primary-green);
}

.icon.red {
   color: var(--primary-red);
}

.contentContainer h3 {
   max-width: 300px;
   margin: 0 0 12px 0;
   text-align: center;
   color: var(--primary-text);
   font-weight: 700;
   font-size: 22px;
   line-height: 27px;
}

.contentContainer > div {
   width: 100%;
}

.btnsContainer {
   display: flex;
   justify-content: flex-end;
}

.btnsContainer button:last-of-type {
   margin-left: 8px;
}

.btnsContainer.centered {
   justify-content: center;
}

@media screen and (max-width: mobile-threshold-width) {
   .modalBody {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
   }

   .btnsContainer {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      flex-direction: column;
   }

   .btnsContainer button {
      padding: 12px 5px;
      width: 100%;
      border-radius: 0;
   }

   .btnsContainer button:first-of-type {
      display: none;
   }

   .btnsContainer button:last-of-type {
      margin-left: 0;
   }
}
