@value variables: "variables.css";
@value first-table-shrink-width from variables;
@value mobile-threshold-width from variables;

.mainContainer {
   margin-top: 172px;
   width: 415px;
}

.mainContainer h1 {
   margin: 0 0 24px 0;
   font-weight: 700;
   font-size: 34px;
   line-height: 40px;
   color: var(--primary-text);
}

.mainContainer p {
   margin: 0 0 24px 0;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
}

.banner {
   margin-bottom: 24px;
}

.input {
   width: 100% !important;
   margin-bottom: 48px;
}

.buttonsContainer {
   display: flex;
   justify-content: space-between;
}

@media (max-width: first-table-shrink-width) {
   .buttonsContainer {
      flex-direction: column-reverse;
   }

   .button {
      width: 100%;
      margin-bottom: 8px;
   }
}

@media (max-width: mobile-threshold-width) {
   .mainContainer {
      margin-top: 68px;
      max-width: 415px;
      width: 100%;
   }

   .mainContainer h1 {
      font-size: 22px;
      line-height: 26px;
   }
}
