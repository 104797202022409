.container {
   display: flex;
   flex-direction: column;
   margin-bottom: 24px;
   padding: 24px 16px;
   border: 1px solid #dfdfe3;
   border-radius: 8px;
}

.removeItemBtnContainer {
   margin-bottom: 24px;
   display: flex;
   justify-content: flex-end;
   align-items: center;
}

.removeItemBtnContainer.withAlertIcon {
   justify-content: space-between;
}

.removeItemBtnContainer .removeItemBtn {
   width: 24px;
   height: 24px !important;
   padding: 0;
   color: #3e3e4b;
}

.field {
   margin-bottom: 24px;
}

.externalCommercialNetworkNumberInput input {
   color: #39414f;
}

.addPalletsItemBtnContainer {
   display: flex;
   justify-content: flex-start;
}

.container .dropdownBtn,
.container .dropdownBtn {
   padding: 0;
   justify-content: flex-start;
   border: none;
   font-weight: 400;
   color: var(--gray-primary-text-color);
   background-color: inherit;
}

.container .dropdownBtn:disabled,
.container .dropdownBtn:disabled {
   background-color: inherit !important;
}
.damagedContainer {
   display: flex;
   gap: 24px;
   margin-bottom: 24px;
}
.damagedContainer span {
   font-size: 14px;
   font-weight: 400;
   color: var(--gray);
}
