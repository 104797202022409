.mainContainer {
    font-size: 14px;
    line-height: 24px;
    color: var(--secondary-text);
}

.internalOrderIdContainer {
    margin-left: 2px;
}

.internalOrderIdContainer.clickable {
    border-radius: 4px;
    padding: 2px 4px;
    display: inline-flex;
    gap: 2px;
    font-weight: 700;
    color: var(--primary-green);
}

.internalOrderIdContainer.clickable:hover {
    cursor: pointer;
    background-color: #EFEFF1;
}

.internalOrderIdContainer svg {
    height: 20px;
    width: 20px;
}