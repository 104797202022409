.container {
   padding: 12px 24px 12px 24px;
   border: 1px dashed var(--gray-upload-images-btn-border);
   border-radius: 4px;
   background-color: var(--gray-upload-images-btn);
   cursor: pointer;
}

.container input {
   display: none;
}

.container span {
   font-weight: 700;
   line-height: 24px;
   color: var(--gray-upload-images-btn-text);
}
