@value variables: "variables.css";
@value mobile-threshold-width from variables;

.layoutContainer {
   display: flex;
}
.contentContainer {
   background-color: var(--light-gray);
   width: calc(100vw - 248px);
   height: 100vh;
}
.topBar {
   background-color: white;
   border-bottom: 2px solid var(--secondary-gray);
   width: auto;
}
.topBarHeader {
   color: var(--gray-primary-text-color);
   font-size: 22px;
   line-height: 26px;
}
.childrenContainer {
   height: calc(100vh - 75px);
   overflow-y: auto;
   padding: 0 48px;
}
.banner {
   margin-top: 16px;
}
.mobileOnlyLogo {
   display: none;
}

@media (max-width: mobile-threshold-width) {
   .contentContainer {
      height: auto;
      width: 100vw;
      padding-bottom: 85px;
   }
   .topBarHeader {
      display: none;
   }
   .mobileOnlyLogo {
      display: block;
      height: 26px;
   }
   .childrenContainer {
      width: 100%;
      padding: 0 !important;
      overflow-y: visible;
      height: auto;
   }
}
