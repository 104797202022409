.modalContent {
   margin-bottom: 48px;
}

.formContentContainer {
   width: 100%;
}

.modalContent .additionalChargesList {
   margin-bottom: 12px;
}

.bannersContainer {
   margin-top: 10px;
   margin-bottom: 24px;
}

.errorBanner {
   margin-bottom: 12px;
   width: 97%;
}
