.modalContent {
   margin-bottom: 72px;
}

.modalText {
   margin: 0;
   text-align: center;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--primary-text);
}
