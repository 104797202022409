.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox input[type='checkbox'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1.5px solid #d2d2dc;
  border-radius: 4px;
  margin: 0 12px 0 0;
  display: grid;
  place-content: center;
  flex: 0 0 20px;
  cursor: pointer;
}

.checkbox.error input[type='checkbox'] {
  border: 1.5px solid #fb4c5f;
}

.checkbox input[type='checkbox']:hover {
  border: 2px solid #009653;
}

.checkbox input[type='checkbox']:before {
  content: '';
  width: 10px;
  height: 8px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.indeterminate.checkbox input[type='checkbox']:before {
  content: '';
  width: 10px;
  height: 10px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  transform-origin: bottom left;
  clip-path: none;
  border-radius: 1px;
}
.indeterminate.checkbox input[type='checkbox']:checked {
  background-color: white;
  border: 1px solid #ceced4;
}
.checkbox input[type='checkbox']:checked:before {
  transform: scale(1);
  background-color: white;
}
.indeterminate.checkbox input[type='checkbox']:checked:before {
  transform: scale(1);
  background-color: #009653;
}

.checkbox input[type='checkbox']:checked {
  background-color: #009653;
  border: none;
}
.checkbox label {
  font-size: 14px;
  line-height: 24px;
  color: black;
  cursor: pointer;
}
.checkbox.disabled input[type='checkbox'] {
  background-color: #f9f9fa;
}
.checkbox.disabled input[type='checkbox']:checked {
  background-color: #c2c3d0;
}
.checkbox.disabled label {
  color: #b1b2c2;
}
