.additionalOrdersList {
   width: 100%;
   max-height: 260px;
   margin: 0 0 24px 0;
   padding: 0;
   list-style: none;
   overflow: auto;
}

.listItem {
   margin-bottom: 24px;
   display: flex;
   align-items: flex-start;
}

.listItem:last-of-type {
   margin-bottom: 0;
}

.checkbox {
   flex-shrink: 0;
}

.checkbox label {
   margin-right: 40px;
   font-weight: 700;
   color: var(--primary-text);
}

.checkbox input[type='checkbox'] {
   margin-right: 24px;
}
