.row {
   margin-bottom: 24px;
   font-size: 14px;
   line-height: 24px;
}

.row .label {
   margin: 0;
   color: var(--secondary-text);
}
.row p {
   margin: 4px 0 0 0;
   color: var(--gray-primary-text-color);
}
.row.flex {
   display: flex;
   align-items: center;
   gap: 36px;
}
.addressTitle {
   width: 100%;
   border-bottom: 1px solid var(--gray-border);
   padding-bottom: 4px;
   line-height: 24px;
   font-size: 14px;
}
