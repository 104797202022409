.autocompleteContainer {
   position: relative;
}
.autocompleteContainer.fullWidth,
.autocompleteContainer.fullWidth > div,
.autocompleteContainer.fullWidth input {
   width: 100%;
}
.autocompleteContainer p {
   font-size: 14px;
   line-height: 24px;
   color: #9ea3ad;
}
.autocomplete {
   width: 250px;
   height: 48px;
   position: relative;
   display: flex;
   justify-content: space-between;
   align-items: center;
   transition: ease-in-out 0.1s;
}
.autocomplete input {
   width: 100%;
   height: 100%;
   border: 1px solid #c7cad0;
   border-radius: 4px;
   cursor: pointer;
   padding: 12px 40px 12px 20px;
   box-sizing: border-box;
   font-size: 14px;
   line-height: 24px;
   color: #39414f;
   background-color: white;
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   -webkit-appearance: none;
}
.autocomplete.autocompleteExpanded input {
   box-shadow: 0 0 0 2px rgba(148, 247, 163, 0.8);
   border: 1px solid #009653;
}
.autocomplete:hover input {
   border: 1px solid #009653;
}
.autocomplete input:focus {
   border: 1px solid #009653;
}
.autocomplete input:focus-visible {
   outline: none;
}
.autocomplete input::placeholder {
   color: #9ea3ad;
}
.autocomplete input:disabled {
   background-color: #f9f9fa;
   border: 1px solid #e0e0e7;
   cursor: not-allowed;
}
.autocompleteError input {
   border: 1px solid #ff385f;
}
.chevronDownIconContainer {
   position: absolute;
   width: 20px;
   height: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   right: 25px;
}
.chevronDownIconContainer.withClearIcon .chevronDownIcon{
   display: none;
}
.chevronDownIcon {
   display: inline;
}
.chevronDownIconContainer.withClearIcon .clearIcon{
   display: inline;
}
.clearIcon {
   display: none;
}
.autocompleteLabel {
   margin: 0 0 4px 0;
}
.helperText {
   margin: 4px 0 0 0;
}
.helperText.helperTextError {
   color: #ff385f;
}
.suggestionsContainer {
   display: none;
   max-height: 300px;
   overflow: auto;
   background-color: white;
}
.suggestionsContainer.withPortal {
   position: fixed;
   z-index: 201;
}
.suggestionsContainerExpanded {
   display: flex;
   flex-direction: column;
   position: absolute;
   top: 50px;
   padding: 7px 0;
   z-index: 99;
   box-shadow: 0 21px 9px rgba(17, 17, 17, 0.01), 0 12px 7px rgba(17, 17, 17, 0.05),
      0 5px 5px rgba(17, 17, 17, 0.09), 0 1px 3px rgba(17, 17, 17, 0.1),
      0 0 0 rgba(17, 17, 17, 0.1);
   border-radius: 8px;
}
.suggestionsContainerExpanded.containerWithLabel {
   top: 78px;
}
.suggestion {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   cursor: pointer;
   font-size: 14px;
   line-height: 24px;
   padding: 12px;
   box-sizing: border-box;
   background-color: white;
   color: #39414f;
   transition: ease-in-out 0.2s;
}
.suggestion:hover {
   background-color: #e3fde5;
   color: #00af50;
   font-weight: 700;
}
.suggestion.selected {
   font-weight: 700;
   color: #00af50;
   background-color: #e3fde5;
}
.suggestion span {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
}
.suggestion .description {
   font-size: 11px;
   line-height: 14px;
   color: #94949F;
}
.noSuggestionsMessage {
   font-size: 14px;
   line-height: 24px;
   padding: 12px;
   box-sizing: border-box;
   background-color: white;
   color: #39414f;
   display: flex;
   justify-content: flex-start;
   transition: ease-in-out 0.2s;
}
.noSuggestionsMessage span {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
}
.dropdownBtn {
   padding: 8px 12px;
   border-top: 1px solid #c7cad0;
}
