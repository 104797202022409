@value variables: "variables.css";
@value mobile-threshold-width from variables;

.dropdownContainer {
   position: relative;
   cursor: pointer;
}
.dropdownInnerContainer {
   display: flex;
   align-items: center;
   padding: 8px 12px;
   border-radius: 4px;
}
.innerContainerActive,
.dropdownInnerContainer:hover {
   background-color: var(--secondary-gray);
}
.expandedContainer {
   scale: 0;
   position: absolute;
   background-color: white;
   top: 50px;
   left: 0;
   width: 100%;
   padding: 20px 12px;
   box-sizing: border-box;
   transition: ease-in-out 0.2s;
   pointer-events: none;
   box-shadow: 1px 8px 24px 0px rgba(66, 68, 90, 0.1);
   border-radius: 8px;
   z-index: 3;
}
.expandedContainer.active {
   scale: 1;
   pointer-events: all;
}

.dropdownInnerContainer p,
.expandedContainer p {
   margin: 0 17px 0 12px;
}

.expandedContainer.active:hover {
   background: #f7f7f8;
}

@media (max-width: mobile-threshold-width) {
   .desktopOnly {
      display: none;
   }
   .expandedContainer {
      width: 250px;
      right: 0;
      left: auto;
   }
}
