.formRow {
   display: flex;
   flex-direction: row;
   margin-bottom: 20px;
   gap: 24px;
   width: 448px;
}
.formRow button {
   height: 48px;
}
.deliveryAddressTitle {
   border-bottom: 1px solid var(--gray-border);
   display: flex;
   align-items: center;
   padding-bottom: 8px;
   margin-bottom: 12px;
}
.deliveryAddressTitle svg {
   margin-left: 16px;
}
.deliveryAddressTitle h4 {
   margin: 0;
}
.deliveryAddressWrapper:last-of-type {
   margin-top: 54px;
}

.phoneNumberInput input::-webkit-outer-spin-button,
.phoneNumberInput input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

.inputInfo {
   display: flex;
   font-size: 14px;
   align-items: center;
   margin-bottom: 6px;
   color: var(--secondary-text);
}
.formRow .inputInfo svg {
   position: static;
   margin-right: 8px;
}
.checkbox input[type='checkbox']:checked {
   background-color: var(--primary-green) !important;
   border: none;
}

.phoneNumberInput input::-webkit-outer-spin-button,
.phoneNumberInput input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

.phoneNumberInput input[type='number'] {
   -moz-appearance: textfield;
}
