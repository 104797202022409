@value variables: "variables.css";
@value mobile-threshold-width from variables;

.container {
   display: flex;
   height: 100vh;
}
.containerLeft {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-top: 124px;
   width: 615px;
}
.innerLeftContainer {
   width: 415px;
}
.containerRight {
   width: calc(100vw - 615px);
   position: relative;
   background-size: cover;
   background-position: center;
}

.innerLeftContainer p {
   font-size: 11px;
   line-height: 13px;
   color: var(--gray);
   position: absolute;
   bottom: 48px;
}

@media (max-width: mobile-threshold-width) {
   .container {
      height: 100%;
      justify-content: center;
   }
   .containerLeft {
      border-radius: 16px;
      width: 90%;
      padding: 50px 0;
   }
   .innerLeftContainer {
      border-radius: 16px;
      width: 100%;
      z-index: 9;
      background-color: white;
      padding: 24px;
      box-sizing: border-box;
   }
   .containerRight {
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 0;
      background-size: cover;
   }
   .imageOverlay {
      width: 100%;
   }
   .innerLeftContainer p {
      margin-top: 48px;
      position: static;
   }
}
