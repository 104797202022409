@value variables: "variables.css";
@value mobile-threshold-width from variables;

.headerContainer {
   display: flex;
   margin-bottom: 32px;
}

.headerLeft {
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.headerLeft p {
   margin: 0;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
}

.headerLeftInnerContainer {
   display: flex;
   align-items: center;
   margin-bottom: 10px;
}

.headerLeftInnerContainer h2 {
   color: var(--primary-text);
   font-size: 28px;
   line-height: 35px;
   margin: 0 12px 0 0;
}

@media (max-width: mobile-threshold-width) {
   .headerContainer {
      flex-direction: column;
   }
}
