.actions {
   display: flex;
   gap: 24px;
   justify-content: flex-end;
}

.modalContent {
   margin: 0 auto 40px auto;
   text-align: center;
   position: relative;
}
