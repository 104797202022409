.divider {
   background-color: #e6e8eb;
}

.divider.horizontal {
   width: 100%;
   height: 1px;
}

.divider.vertical {
   height: 100%;
   width: 1px;
}
