.container {
   margin-top: 48px;
}

.loader {
   display: flex;
   justify-content: center;
}

.banner {
   margin-bottom: 24px;
}

.container table:first-child tbody tr td:last-child,
.container table:first-child th:last-child {
   text-align: right;
}
