@value variables: "variables.css";
@value mobile-threshold-width from variables;

.topContainer {
   display: flex;
   margin: 24px 0;
}
.topContainer button {
   height: 48px;
   margin-left: 24px;
}
.searchInput {
   color: var(--black);
   height: 48px;
}
.uploadPDFBtnHeader {
   margin-left: 24px;
}
.searchInput svg {
   top: 17px !important;
   width: 17px;
   height: 17px;
}
.searchInput input {
   height: 48px;
   box-sizing: border-box;
}
.btnsContainer {
   display: inline-block;
}
.btnsContainer div {
   display: flex;
   gap: 24px;
}
.btnsContainer .actionBtnDanger {
   color: var(--primary-red);
}
.actionBtnDanger > svg {
   margin-right: 8px;
}
.actionsCell .btnsContainer button {
   height: 24px !important;
   padding: 0;
}
.actionsCell {
   padding-right: 5px;
}
.documentsTable tr td:first-of-type {
   padding-left: 5px;
}
.documentsTable tr td:last-of-type {
   padding-right: 5px;
}
.documentsTable th:last-of-type {
   padding-right: 5px;
}
.documentsTable table tbody tr {
   border-bottom: 1px solid var(--secondary-gray);
}
.documentsTable table tbody tr:last-of-type {
   border-bottom: none;
}
.documentsTable .canceledInvoiceAlertCell {
   width: 25px;
   padding-right: 5px;
}
.alertIconContainer {
   margin-top: 5px;
   display: inline-block;
}
.dateCell {
   padding: 12px 24px;
}
.loaderContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   height: calc(100vh - 400px);
}

@media screen and (max-width: mobile-threshold-width) {
   .documentsTable {
      overflow: scroll;
   }
}
