.tableHeader th {
   padding: 12px 24px 12px 0;
   font-weight: 700;
   color: var(--gray-primary-text-color);
   white-space: nowrap;
}

.tableHeader th:last-child {
   padding-right: 0;
}

.tableHeader.withEmptyColumn th:first-child {
   padding-right: 0;
}
