.tableRow {
   border-bottom: 1px solid #e6e8eb;
   padding: 12px 0;
}
.tableRow:last-of-type {
   border-bottom: none;
}
.tableRowActive,
tr.tableRowActive:hover {
   background: #e4f1e6;
}
