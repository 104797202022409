@value variables: "variables.css";
@value mobile-threshold-width from variables;

.tableHeader {
   position: sticky;
   top: 0;
   z-index: 1;
   background-color: white;
}

.tableHeader th:first-of-type {
   padding-left: 24px;
}

@media screen and (max-width: mobile-threshold-width) {
   .tableHeader th {
      white-space: nowrap;
   }
}
