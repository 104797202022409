.tableHeader {
   position: sticky;
   top: 0;
   z-index: 1;
   background-color: white;
}

.tableHeader th:first-of-type {
   padding-left: 24px;
}

.tableHeader th:last-of-type {
   padding-left: 45px;
}
