.header {
   display: flex;
   align-items: center;
}

.header.leftSide {
   justify-content: flex-start;
}

.header.rightSide {
   justify-content: flex-end;
}

.header.spaceBetween {
   justify-content: space-between;
}
