.additionalChargesList {
   max-height: 490px;
   width: 100%;
   margin: 0;
   padding: 0;
   list-style: none;
   overflow: auto;
}

.additionalChargesList li {
   margin-bottom: 24px;
}

.additionalChargesList li:last-of-type {
   margin-bottom: 0;
}
