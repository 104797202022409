@value variables: "variables.css";
@value mobile-threshold-width from variables;

.mainContainer {
   display: flex;
   align-items: center;
   margin-bottom: 24px;
}

.mainContainer p {
   margin: 0;
   font-size: 14px;
   line-height: 24px;
   color: var(--gray-primary-text-color);
}
.badgeContainer {
   width: 135px;
   flex: 0 0 auto;
}
@media screen and (max-width: mobile-threshold-width) {
   .badgeContainer {
      flex-shrink: 0;
   }
}
