.lostDamagedPalletsImagesRow {
   width: 100%;
   height: 60px;
   position: relative;
   border: 0;
}

.lostDamagedPalletsImagesCell {
   box-sizing: border-box;
   position: absolute;
   top: 0;
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding: 0 40px 10px 24px;
   width: 100%;
   height: 60px;
}
