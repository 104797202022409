@value variables: "variables.css";
@value first-table-shrink-width from variables;

.modalContent :global .mantine-Modal-modal {
   max-width: 1500px;
   width: 100%;
}

.modalHeaderWithSmallMargin {
   margin-bottom: 12px;
}

.modalBody {
   padding: 0;
}

.damagedPalletsToSendWarning {
   margin: 0 0 48px 0;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
}

.damagedPalletsToSendBanner {
   margin-bottom: 48px;
   align-items: flex-start;
   font-weight: 400;
   font-size: 400px;
   line-height: 24px;
}

.damagedPalletsToSendBanner p {
   margin: 0 0 5px 0;
}

.damagedPalletsToSendBanner ul {
   margin: 0;
   padding-left: 25px;
}

.damagedPalletsToSendBanner li span {
   font-weight: 700;
}

.banner {
   margin-bottom: 48px;
}

.infoBanner {
   margin-bottom: 24px;
}

.scrollableOuterContainer {
   margin-bottom: 24px;
}

.scrollableInnerContainer {
   max-height: 380px;
}

.bannersContainer {
   margin-top: 10px;
   margin-bottom: 24px;
}

.errorBanner {
   margin-bottom: 12px;
}

.btnContainer {
   margin-top: 48px;
   display: flex;
   justify-content: flex-end;
}

.addSendPalletsItemBtn {
   margin-bottom: 24px;
}

.invalidSendDamagePalletsAmountModalContent {
   margin-bottom: 48px;
}

.invalidSendDamagePalletsAmountModalContent p {
   text-align: center;
   font-size: 14px;
   line-height: 24px;
   color: var(--primary-text);
}

.invalidSendDamagePalletsAmountModalButton {
   padding: 12px 60px;
}

.loader {
   width: 100%;
   height: 350px;
   display: flex;
   justify-content: center;
   align-items: center;
}

@media (max-width: first-table-shrink-width) {
   .modalHeaderWithSmallMargin {
      margin-bottom: 8px;
   }

   .damagedPalletsToSendWarning {
      margin-bottom: 24px;
   }

   .damagedPalletsToSendBanner {
      margin-bottom: 24px;
   }

   .mobileScrollableContainer {
      overflow: auto;
      height: calc(100vh - 140px);
      padding-right: 5px;
   }

   .mobileScrollableContainer.shorter {
      height: calc(100vh - 300px);
   }

   .bannersContainer {
      margin-top: 20px;
   }

   .btnContainer {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
   }

   .btnContainer button {
      width: 100%;
      border-radius: 0;
   }
}
