tr.tableRow {
   height: 56px;
   border-bottom: 1px solid var(--secondary-gray);
}

.tableRow td:first-of-type {
   padding-left: 24px;
}

.tableRow .totalSum {
   font-weight: 700;
}
