@value variables: "variables.css";
@value mobile-threshold-width from variables;

.summary {
   margin-top: 24px;
   display: flex;
   max-width: 450px;
   width: 100%;
   justify-content: space-between;
   color: var(--primary-text);
}

.summary h3 {
   margin: 0;
   font-size: 18px;
   line-height: 24px;
}

.summary span {
   font-weight: 700;
   font-size: 18px;
   line-height: 24px;
}

.summary .summaryTextLarge {
   font-size: 22px;
}

@media screen and (max-width: mobile-threshold-width) {
   .summary {
      width: 100%;
   }
}
