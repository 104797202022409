.mainContainer {
   max-width: 974px;
   width: 100%;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
}

.mainContainer svg {
   margin-bottom: 25px;
}

.mainContainer h3 {
   margin: 0 0 12px 0;
   font-weight: 700;
   font-size: 22px;
   line-height: 26px;
   color: var(--gray-primary-text-color);
}

.mainContainer p {
   margin: 0;
   font-size: 14px;
   line-height: 24px;
   color: var(--gray-primary-text-color);
}

.mainContainer .additionalFeeList {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 0;
   max-width: 500px;
   font-size: 14px;
   line-height: 24px;
   color: var(--gray-primary-text-color);
}

.additionalFeeListItem::marker {
   font-size: 12px;
   color: var(--gray-primary-text-color);
}
