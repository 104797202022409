.additionalPalletsList {
   width: 55%;
   margin: 0;
   padding: 0;
   list-style: none;
}

.listItem {
   margin-bottom: 4px;
   display: flex;
   justify-content: space-between;
   font-size: 14px;
   line-height: 24px;
   color: var(--primary-text);
}
