.infiniteScrollInnerContainer {
    box-shadow: none;
    max-height: 600px;
    height: 100%;
}

.infiniteScrollOuterLoader {
    height: 600px;
}

.tabs {
    margin-bottom: 24px;
}