.headerRow,
.row {
   display: flex;
   align-items: center;
   gap: 16px;
}
.headerRow {
   padding-bottom: 12px;
   border-bottom: 1px solid var(--gray-border);
   margin-bottom: 36px;
}
.headerCell,
.cell {
   max-width: 190px;
   width: 100%;
}
.headerCell:first-of-type,
.cell:first-of-type {
   max-width: 150px;
   width: 100%;
}
.headerCell {
   font-size: 14px;
   font-weight: 700;
   white-space: nowrap;
}
.container {
   display: flex;
   flex-direction: column;
}
.cell:first-of-type h3 {
   margin: 0;
   font-size: 14px;
   font-weight: 400;
}
.cell:first-of-type p {
   margin: 0;
   font-size: 12px;
   color: var(--secondary-text);
}
.row {
   margin-bottom: 24px;
}
.row:last-of-type {
   margin-bottom: 12px;
}
