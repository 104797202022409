@value variables: "variables.css";
@value first-table-shrink-width from variables;
@value mobile-threshold-width from variables;

.mainContainer {
   margin-top: 147px;
   width: 440px;
}

.iconContainer {
   display: inline-flex;
   height: 60px;
   width: 60px;
   margin-bottom: 30px;
}

.icon {
   height: 100%;
   width: 100%;
}

.mainContainer h1 {
   margin: 0 0 24px 0;
   font-weight: 700;
   font-size: 34px;
   line-height: 40px;
   color: var(--primary-text);
}

.banner {
   margin-bottom: 24px;
}

.mainContainer p,
.mainContainer li {
   margin: 0;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
   letter-spacing: 0.001em;
}

.mainContainer p {
   margin-bottom: 4px;
}

.mainContainer p:last-of-type {
   margin-bottom: 24px;
}

.boldText,
.mainContainer p.boldText {
   font-weight: 700;
}

.mainContainer ol {
   margin: 0 0 30px 0;
   padding-left: 20px;
}

.mainContainer li {
   margin-bottom: 4px;
}

@media (max-width: mobile-threshold-width) {
   .mainContainer {
      margin-top: 26px;
      max-width: 415px;
      width: 100%;
   }

   .mainContainer h1 {
      font-size: 22px;
      line-height: 26px;
   }

   .mainContainer p:last-of-type {
      margin-bottom: 36px;
   }

   .mainContainer ol {
      margin-bottom: 20px;
   }
}
