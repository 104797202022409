@value variables: "variables.css";
@value mobile-threshold-width from variables;

.cardsContainerSm,
.cardsContainer {
   display: flex;
   flex-wrap: wrap;
}

.cardLarge {
   height: 284px;
   width: 348px;
   margin-right: 24px;
   border-radius: 8px;
}

.cardDisabled {
   opacity: 0.6;
   pointer-events: none;
   z-index: 99;
   background-color: #efeff1;
}

.cardLarge h2 {
   font-size: 18px !important;
   line-height: 24px !important;
}

.cardContent {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 12px;
}

.addressSelect {
   width: 250px;
}

.cardText {
   margin: 0;
   text-align: center;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-dark-gray);
   width: 222px;
}

@media screen and (max-width: mobile-threshold-width) {
   .cardsContainer {
      flex-direction: column;
   }

   .cardLarge {
      margin: 16px 0;
      width: 100% !important;
   }
}
