.palletsSummary {
   display: flex;
   align-items: center;
   font-weight: 700;
   font-size: 22px;
   line-height: 28px;
   color: var(--primary-text);
}

.palletsSummary > span {
   display: flex;
   align-items: center;
}

.palletsSummary h3 {
   margin: 0 48px 0 0;
}

.palletsSummary .palletsSummaryDivider {
   margin: 0 24px;
   display: inline-block;
   height: 32px;
}
