body {
   margin: 0;
   font-family: 'Source Sans Pro', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   color: var(--primary-text);
}

:root {
   --primary-green: #009653;
   --dark-green: #007d52;
   --light-green: #00af50;
   --primary-50: #f1f8f3;

   --dark-gray: #4d5562;
   --secondary-dark-gray: #6c727f;
   --gray: #9ea3ad;
   --gray-section-subtitle: #a6a6b2;
   --light-gray: #f3f4f5;
   --secondary-gray: #e6e8eb;
   --gray-text: #6c727f;
   --gray-table-header: #f9fbfb;
   --gray-table-extendable-row: #fbfbfb;
   --gray-background-color: #f0f0f0;
   --gray-primary-text-color: #4a4a4f;
   --gray-secondary-text-color: #39414f;
   --gray-border: #c7cad0;
   --gray-disabled: #c2c3d0;

   --primary-text: #4a4a4f;
   --secondary-text: #808089;

   --black: #121826;

   --modal-dark-gray: #4a4a4f;
   --gray-upload-images-btn: #efeff1;
   --gray-upload-images-btn-border: #ceced4;
   --gray-upload-images-btn-text: #686870;
   --gray-close-preview-img-btn: #d9d9d9;

   --primary-red: #ff385f;
   --light-red: #ffefeb;
   --dark-red: #b71c58;

   --yellow-warning-text: #b58200;
   --yellow-warning-background: #f9e9b2;

   --blue-warning-text: #0c60d6;
   --blue-warning-background: #dff4ff;
   --dark-blue-warning-text: #002ed0;

   --purple-warning-background: #c9ceff;

   --gray-warning-text: #4d5562;
   --gray-warning-background: #e4f1e6;

   --green-warning-background: #ddffe6;

   --red-warning-background: #ffded7;
}

a {
   text-decoration: none;
}

/*Mantine components custom css*/

/* Mantine modal */
.mantine-Modal-modal {
   padding: 48px;
   border-radius: 16px;
   font-family: 'Source Sans Pro', sans-serif;
}

.mantine-Modal-header {
   margin-bottom: 48px;
   align-items: flex-start;
}
.mantine-Modal-title {
   font-weight: 700;
   font-size: 28px;
   line-height: 35px;
   color: var(--gray-primary-text-color);
}

.mantine-Modal-close svg {
   width: 24px;
   height: 24px;
   color: var(--gray-primary-text-color);
}
.mantine-Modal-body {
   padding: 5px;
}

/* Mantine date picker */
.mantine-DatePicker-label {
   color: var(--gray);
}

.mantine-DatePicker-input {
   height: 48px;
   color: var(--gray-primary-text-color);
}

.mantine-DatePicker-label,
.mantine-DatePicker-input {
   font-family: 'Source Sans Pro', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.mantine-DatePicker-input:hover {
   border: 2px solid var(--primary-green);
}

.mantine-DatePicker-input:focus {
   box-shadow: 0 0 0 2px rgba(148, 247, 163, 0.8);
   border: 2px solid var(--primary-green);
}
.mantine-DatePicker-input.error:focus {
   border: 2px solid var(--primary-red);
   box-shadow: none;
}

.mantine-DatePicker-day[data-selected] {
   background-color: var(--primary-green);
}

.mantine-DatePicker-day.current {
   border: 1px solid var(--primary-green);
}

.mantine-DatePicker-monthPickerControlActive,
.mantine-DatePicker-yearPickerControlActive {
   background-color: var(--primary-green);
}

.mantine-DatePicker-monthPickerControlActive:hover,
.mantine-DatePicker-yearPickerControlActive:hover {
   background-color: var(--dark-green);
}

.mantine-DatePicker-wrapper .mantine-Input-rightSection:has(svg) {
   width: 40px;
}

.mantine-DatePicker-wrapper .mantine-Input-rightSection svg {
   width: 18px;
   height: 18px;
   color: rgb(18, 24, 38);
}

.mantine-DatePicker-wrapper .mantine-Input-rightSection path {
   stroke: rgb(18, 24, 38);
   stroke-width: 0.5px;
}

/* InfiniteScroll component */
.infinite-scroll-component {
   overflow: unset !important;
}

/* fslightbox */
.fslightbox-toolbar-button:first-child {
   display: none;
}

@media (max-width: 1050px) {
   /*Mantine components custom css*/
   .mantine-Modal-inner {
      padding: 0;
   }

   .mantine-Modal-modal {
      height: 100vh;
      width: 100vw;
      padding: 24px 16px 24px 16px;
      border-radius: 0;
   }

   .mantine-Modal-header {
      margin-bottom: 24px;
   }

   .mantine-Modal-title {
      font-size: 18px;
      line-height: 24px;
   }

   .mantine-Modal-body {
      max-height: calc(100vh - 100px);
      height: 100%;
      padding: 0;
   }
}
