@value variables: "variables.css";
@value mobile-threshold-width from variables;

.dateContainer {
   display: flex;
   align-items: center;
}

.dateInfo {
   margin-right: 86px;
}
.dateInfo p {
   font-size: 14px;
   line-height: 24px;
   color: var(--gray);
   margin: 0 0 16.5px 0;
}
.dateInfo div {
   color: var(--primary-text);
   font-size: 14px;
}

.dateInfo svg {
   margin-right: 14px;
}
.dateInfo div {
   display: flex;
   align-items: center;
}

@media screen and (max-width: mobile-threshold-width) {
   .dateContainer {
      flex-direction: column;
      align-items: flex-start;
   }
   .dateContainer div:first-child {
      margin-bottom: 48px;
   }
}
