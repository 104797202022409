.header {
   margin-bottom: 24px;
}

.searchInput {
   width: 315px;
}

.searchInput svg {
   top: 17px;
   width: 17px;
   height: 17px;
}
