@value variables: "variables.css";
@value mobile-threshold-width from variables;

.mainContainer {
   max-width: 235px;
   margin: 150px auto 150px auto;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.mainContainer.small {
   margin: 54px auto;
}

.mainContainer p {
   margin: 0 0 28px 0;
   text-align: center;
   font-size: 14px;
   line-height: 24px;
   font-weight: 700;
   color: var(--gray-text);
}

.mainContainer p.withoutMargin {
   margin: 0;
}

.iconContainer {
   margin-bottom: 12px;
}
.arrowDown {
   display: none;
}
@media screen and (max-width: mobile-threshold-width) {
   .mainContainer {
      margin: 54px auto 54px auto;
      max-width: 235px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
   }
   .mainContainer p {
      text-align: center;
   }
   .arrowDown {
      margin: 30px 10px 40px 0;
      display: block;
      z-index: 0;
      right: 54%;
   }
}
