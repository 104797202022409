.track {
   width: 55px;
   height: 24px;
   border: none;
   border-radius: 15px;
   background-color: #a6a6b2;
}

.root input:checked + .track {
   background-color: var(--primary-green);
}

.thumb {
   width: 18px;
   height: 18px;
   left: 4px;
   border-radius: 9px;
   box-shadow: 1px 3px 4px 0px #6a6a8180;
}

.root input:checked + * > .thumb {
   left: calc(100% - 20px - 2px);
}

.trackLabel {
   padding: 0 2px 0 2px;
   margin-left: 25px;
}

.root input:checked + * > .trackLabel {
   padding: 0 2px 0 2px;
   margin-right: 25px;
}

.label {
   display: flex;
   align-items: center;
   font-family: 'Source Sans Pro', sans-serif;
}

.disabledSwitch .label {
   color: #3e3e4b;
}

.disabledSwitch .track {
   cursor: not-allowed;
   opacity: 0.4;
}
