div.row {
    margin-bottom: 0;
    padding: 18px 6px 18px 0;
    cursor: pointer;
}

div.row:hover {
    background-color: #f7f7f8;
}

.cell:first-of-type {
    max-width: none;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
}
