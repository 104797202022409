.modalContent {
   margin-bottom: 72px;
}

.description {
   margin: 0 0 48px 0;
   font-size: 14px;
   line-height: 24px;
}

.selectedPalletsAmout {
   display: flex;
   justify-content: flex-end;
   font-weight: 700;
   font-size: 18px;
   line-height: 24px;
   color: var(--primary-text);
}

.selectedPalletsAmout span:first-of-type {
   margin-right: 48px;
}

.selectedPalletsAmout span:last-of-type {
   margin-right: 40px;
}
