.card {
   padding: 24px !important;
   height: 415px !important;
   width: 100% !important;
   border-radius: 8px;
   cursor: default;
}

.card:hover {
   background-color: transparent;
}

.cardPalletImage {
   margin-top: 25px;
}

.cardBottomContent p {
   margin: 0;
   font-size: 14px;
   line-height: 24px;
}

p.dimensions {
   margin-bottom: 24px;
}

p.contractPriceDescription {
   margin-bottom: 4px;
}

p.contractPrice {
   margin-bottom: 18px;
   color: var(--primary-text);
}

.arrowIcon {
   margin-bottom: 12px;
   color: #3e3e4b;
}

p.customPriceDescription {
   margin-bottom: 4px;
}

.dimensions,
.contractPriceDescription,
.customPriceDescription {
   color: var(--secondary-text);
}

.customPriceDescription span {
   font-weight: 700;
}
