.container {
   display: flex;
   flex-direction: column;
   margin-bottom: 24px;
   padding: 24px 16px;
   border: 1px solid #dfdfe3;
   border-radius: 8px;
}

.dataItem,
.dataItemsGroup {
   margin-bottom: 24px;
}

.dataItem {
   display: flex;
   flex-direction: column;
}

.dataItemsGroup {
   display: flex;
   justify-content: space-between;
}

.dataItemsGroup:last-of-type {
   margin-bottom: 0;
}

.dataItemsGroup > div {
   display: flex;
   flex-direction: column;
}

.dataItemsGroup div:first-of-type {
   max-width: calc(100% - 85px);
}

.label {
   margin-bottom: 4px;
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
}

.value {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   font-size: 14px;
   line-height: 24px;
   color: var(--primary-text);
}
