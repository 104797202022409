.container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 48px 0 130px 0;
}

.loader {
   margin-bottom: 30px;
}

.container p {
   width: 150px;
   margin: 0;
   text-align: center;
   font-size: 14px;
   line-height: 24px;
   color: var(--gray-primary-text-color);
}
