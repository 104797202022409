.palletDimensions,
.palletPrice {
   font-size: 14px;
   line-height: 24px;
}

.palletDimensions {
   margin: 0 0 4px 0;
   color: var(--secondary-text);
}

.palletPrice {
   margin: 0;
   color: var(--primary-text);
}

.palletPrice span {
   font-weight: 700;
}
