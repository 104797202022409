.container {
   border: 1px solid #c7cad0;
   padding: 24px 16px 0 16px;
   box-sizing: border-box;
   border-radius: 8px;
   margin-bottom: 24px;
}
.palletRow div span,
.cardRow span {
   font-size: 14px;
   line-height: 24px;
   color: var(--secondary-text);
}
.cardRow p {
   margin-top: 4px;
   margin-bottom: 0;
}
.cardRow {
   margin-bottom: 24px;
}
.cardRow.topRow {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
}
.palletRow {
   display: flex;
   justify-content: space-between;
}
.palletRow div {
   width: 45%;
}
.palletRow div p {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
}
.calendarRow {
   display: flex;
   align-items: center;
}
.calendarRow svg {
   margin-right: 16px;
}
