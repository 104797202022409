.goToIcon {
   display: flex;
   align-items: center;
   gap: 5px;
   text-decoration: none;
   color: var(--primary-text);
}

.tableCell .actionBtn {
   height: fit-content;
   padding: 0;
   font-weight: 700;
   line-height: 24px;
}

.tableRow {
   border-bottom: 1px solid #e6e8eb !important;
   padding: 12px 0;
}

.bold {
   font-weight: 700;
}

.tableRow .tableCell {
   padding: 12px 24px 12px;
}

.tableRow .tableCell:first-of-type {
   padding-left: 24px;
   width: 40px;
}

.actionContainer {
   display: flex;
   justify-content: flex-end;
}
