.card {
   width: 500px;
   height: 250px;
   border-radius: 8px;
   margin-top: 24px;
}
.card h2 {
   margin: 16px 0 4px 0;
   font-size: 18px !important;
   line-height: 24px !important;
}
.card p {
   margin: 0;
   text-align: center;
   font-size: 14px;
   line-height: 24px;
}
.cardAddress {
   margin-top: 4px !important;
   color: var(--secondary-text);
}
.noStorageText {
   color: var(--primary-red);
}
