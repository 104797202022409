.navItem {
   display: flex;
   align-items: center;
   width: 100%;
   box-sizing: border-box;
   height: 48px;
   color: #9ea3ad;
   font-weight: 700;
   font-size: 14px;
   line-height: 24px;
   padding-left: 24px;
   border-radius: 4px;
}
.navItem:hover {
   background: #f7f7f8;
}
.navItem.active {
   padding-left: 21px;
   background-color: #e3fde5;
   border-left: 3px solid #00af50;
   color: #00af50;
}
.navItem svg {
   margin-right: 15px;
}
