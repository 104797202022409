@value variables: "variables.css";
@value mobile-threshold-width from variables;

.inputContainer {
   margin-top: 24px;
}

.formContainer h1 {
   font-size: 34px;
   line-height: 40px;
   margin-top: 72px;
   margin-bottom: 48px;
   color: var(--gray-primary-text-color);
}

@media (max-width: mobile-threshold-width) {
   .formContainer h1 {
      margin: 24px 0;
      font-size: 22px;
      line-height: 28px;
   }
}
