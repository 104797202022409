@value variables: "variables.css";
@value mobile-threshold-width from variables;

.cardText {
   text-align: center;
   width: 224px;
   font-size: 14px;
   line-height: 24px;
   margin: 12px 0 0 0;
   color: var(--secondary-dark-gray);
}

.supplierNoStorageAddressText {
   max-width: 222px;
   color: var(--primary-red);
}

.addressCard {
   height: 284px;
   width: 348px;
   border-radius: 8px;
   margin-top: 24px;
}

.addressCard h2 {
   font-size: 18px !important;
   line-height: 24px !important;
}

.addressCard p {
   margin: 0;
   white-space: pre-line;
}

@media screen and (max-width: mobile-threshold-width) {
   .addressCard {
      width: 100% !important;
   }
}
