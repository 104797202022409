.headerContainer {
   display: flex;
   margin-top: 56px;
   gap: 27px;
}

.headerContainer > .downDropContainer {
   margin: 0 0 0 auto;
   height: 48px;
}

.header {
   display: flex;
   align-items: center;
   gap: 4px;
   color: var(--primary-text);
}

.headerTitle {
   margin: 0;
   line-height: 48px;
}

.goToIcon {
   height: 24px;
   width: 24px;
}

.subtitles {
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.date {
   color: var(--secondary-text);
   margin: 0;
   font-size: 14px;
   line-height: 24px;
   letter-spacing: 0.001em;
}

.container {
   padding: 24px;
   display: flex;
   flex-direction: column;
   gap: 24px;
   margin: 0;
}

.mainContent {
   display: flex;
   flex-direction: column;
   gap: 24px;
   margin-bottom: 24px;
}

.sectionHeader {
   margin: 0;
   display: flex;
   flex-direction: column;
   gap: 24px;
}

.addressLink {
   display: flex;
   gap: 4px;
   align-items: center;
}

.addressLink p {
   font-size: 14px;
   line-height: 36px;
   letter-spacing: 0.001em;
   margin: 0;
   color: var(--primary-text);
}

.mainContainer {
   display: flex;
   flex-direction: column;
   gap: 32px;
}

.loader {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
}
