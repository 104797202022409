.container {
   width: 48px;
   height: 48px;
   position: relative;
}

.image {
   width: 48px;
   height: 48px;
   object-fit: cover;
   border: 1px solid var(--gray-upload-images-btn-border);
   border-radius: 2px;
}

.image.clickable {
   cursor: pointer;
}

.closeIcon {
   width: 24px;
   height: 24px;
   position: absolute;
   top: -10px;
   right: -10px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   border-radius: 45px;
   z-index: 1;
   color: var(--gray-primary-text-color);
   background-color: var(--gray-close-preview-img-btn);
   cursor: pointer;
}
