.tableRow {
   border-top: 1px solid var(--secondary-gray);
   border-bottom: 1px solid var(--secondary-gray);
}

.tableRow:first-of-type {
   border-top: none;
}

.tableRow:last-of-type {
   border-bottom: none;
}

.tableRowWithoutBottomBorder {
   border-bottom: none;
}
